import type { MessageFunction } from 'vue-i18n';
import homepage from './pages/homepage/index';
import tech from './pages/tech/index';
import statistics from './pages/statistics/index';
import Info from './pages/info/index';
import system from './pages/system/index';

// 土耳其
export default {
    license: {
        powered_by: 'SAIC International {\'|\'} Tüm Hakları Saklıdır',
    },
    auth: {
        login: 'Giriş',
        username: 'Kullanıcı Adı',
        username_placeholder: 'Kullanıcı adınızı girin',
        password: 'Parola',
        password_placeholder: 'Parolanızı girin',
        code: 'Kodu',
        code_placeholder: 'Doğrulama kodunu girin',
        logout: 'Çıkış',
        logout_success: 'Başarıyla çıkış yapıldı',
        day: 'Gün',
        password_expiration: 'Şifre süresi doldu, lütfen değiştirin',
        expire: 'Şifre sona ermeden kalan süre',
        conseils: 'Büyük harf, küçük harf, rakam, özel karakter ({msg}) içermelidir; Şifre uzunluğu 8-16 karakter arasında olmalıdır',
        brand_Selection: 'Marka Seçimi',
        unread_notices: 'Okunmamış Bildirimler',
        new_cases: 'Yeni Durumlar',
        unanswered_cases: 'Cevapsız Durumlar',
        overdue_cases: 'Gecikmiş Durumlar',
    },
    button: {
        query: 'Sorgu',
        editing_position: 'Pozisyonu Düzenle',
        show_theme_editor: 'Temayı Göster/Gizle Düzenleyici',
        change_password: 'Şifre Değiştir',
        old_password: 'Eski Şifre',
        new_password: 'Yeni Şifre',
        confirm_password: 'Şifreyi Onayla',
        button: 'Düğme',
        about: 'Hakkında',
        back: 'Geri',
        go: 'Git',
        home: 'Ana Sayfa',
        confirm: 'Onayla',
        modify: 'Düzenle',
        edit: 'Düzenle',
        new: 'Yeni',
        cancel: 'İptal',
        close: 'Kapat',
        search: 'Ara',
        reset: 'Sıfırla',
        delete: 'Sil',
        add: 'Ekle',
        collapse: 'Daralt',
        expand: 'Genişlet',
        download_template: 'Şablon İndir',
        batch_import: 'Toplu İçe Aktar',
        import: 'İçe Aktar',
        export: 'Dışa Aktar',
        export_fail: 'İçe Aktarma Başarısız',
        detailed: 'Detaylı',
        details: 'Detaylar',
        download: 'İndir',
        toView: 'Görüntüle',
        toViews: 'Resimleri Görüntüle',
        uploadAttachments: 'Ekleri Yükle',
        selectFile: 'Dosya Seç',
        fillingInstructions: 'Doldurma Talimatları',
        release: 'Yayınla',
        release_recall: 'Yayın geri çağırma',
        save: 'Kaydet',
        send: 'Gönder',
        tips: 'İpuçları',
        send_back: 'Geri Gönder',
        examine: 'İncele',
        void: 'Geçersiz',
        more: 'Daha Fazla',
        refresh: 'Yenile',
        selects: 'Lütfen Seçin',
        serial_number: 'Sıra Numarası',
        upload_progress: 'Yükleme İlerlemesi',
        invalid: 'Geçersiz',
        confirm_number: 'Onay Kutu Numarası:',
        empty_data: 'Oluşturulan Verileri Temizle',
        variance_details: 'Fark Detayları',
        daily_record: 'Günlük Kayıt',
        operation_type: 'İşlem Türü',
        field_name: 'Alan Adı',
        edit_role: 'Rolü Düzenle',
        user_role: 'Rol Seçimi',
        assignment_role: 'Rol Atama',
        post: 'Pozisyon',
        sending: 'Gönderiliyor',
        reset_password: 'Şifreyi Sıfırla',
        display_or_not: 'Göster/Değil Göster',
        document: 'Belge',
        specificTemplates: 'özel şablonlar',
        transferOrder: 'Transfer emri',
        forwarding: 'Yönlendi̇rme',
        one_click_notification: 'Bir tıklama bildirimi',
        english_export: 'English Export',
        oneclick_download: 'Tek tıklamayla indirme',
    },
    radio: {
        enabled: 'Etkin',
        disabled: 'Devre Dışı',
        catalogue: 'Katalog',
        individual: 'Bireysel',
        all: 'Tümü',
        yes: 'Evet',
        no: 'Hayır',
    },
    dropdown: {
        close_others: 'Diğerlerini Kapat',
        close_right: 'Sağı Kapat',
        close_left: 'Sola Kapat',
    },
    table: {
        action: 'İşlem',
        explain: 'Açıklama',
        fileName: 'Dosya Adı',
        size: 'Boyut (MB)',
        uploader: 'Yükleyen',
        uploadTime: 'Yükleme Zamanı',
        system: 'Sistem',
        forwarding_time: 'Yönlendirme Zamanı',
        forwarder: 'İleri',
        send_to: 'Gönderin',
        mode: 'Modus',
    },
    pagination: {
        pages: ({ named }: Parameters<MessageFunction>[0]) => `${named('number')}/sayfa`,
        total: ({ named }: Parameters<MessageFunction>[0]) => `toplam ${named('number')} veri`,
        vinCount: ({ named }: Parameters<MessageFunction>[0]) => `toplam ${named('number')} araç`,
    },

    confirm: {
        confirm_deletion: 'Silme işlemini onayla?',
        confirm_void: 'Geçersiz kılma işlemini onayla?',
        confirm_release: 'Yayınlama işlemini onayla?',
        ack: 'Onayla',
        cancel: 'İptal',
        non_records: 'Lütfen en az bir kayıt seçin',
    },
    request: {
        failed_placeholder: 'İstek başarısız oldu, sunucu hatası',
        success_placeholder: 'İstek başarılı',
        auth: {
            login_success: 'Giriş başarılı',
        },
        error: {
            '404': 'İstek yolu mevcut değil',
            '403': 'İzin yok',
        },
    },
    page: {
        global: {
            page_search: 'Sayfa Arama',
        },
        error: {
            not_found: 'Sayfa bulunamadı',
            not_found_des: 'Burada hiçbir şey bulunmuyor',
            auth_failed: 'Yetki reddedildi',
            auth_failed_des: 'Bu sayfaya erişim yasaklandı',
            file_format_error: 'Dosya formatı anormal!',
            preview_failed: 'Önizleme başarısız! Dosya anormal!',
        },
        homepage: {
            homepage: homepage.homepage.trTR,
        },
        system: {
            menu: system.menu.trTR,
            dict: system.dict.trTR,
            dept: system.dept.trTR,
            role: system.role.trTR,
            user: system.user.trTR,
        },
        info: {
            announcement: Info.announcement.announcement.trTR,
            document: Info.document.document.trTR,
        },
        tech: {
            quality: {
                problemTracking: tech.quality.problemTracking.trTR,
                recallActivity: tech.quality.recallActivity.trTR,
                abnormalClaimTracking: tech.quality.abnormalClaimTracking.trTR,
            },
            management: {
                inquiryOd: tech.management.inquiry.trTR,
                permission: tech.management.permission.trTR,
                onSiteSupportHq: tech.management.onSiteSupport.trTR,
                progHistoryHq: tech.management.progHistory.trTR,
                salesRecordHq: tech.management.salesRecord.trTR,
                vehicleInfoHq: tech.management.vehicleInfo.trTR,
                claimHq: tech.management.claim.trTR,
                filterHq: tech.management.filter.trTR,
                newCarlssue: tech.management.newCarIssue.trTR,
                newCarDefinition: tech.management.newCarDefinition.trTR,
                vehicleProductionInfo: tech.management.vehicleProductionInfo.trTR,
                programmingHistoryInformationQuery: tech.management.programmingHistoryInformationQuery.trTR,
                ecuControllerInformationQuery: tech.management.ecuControllerInformationQuery.trTR,
            },
        },
        components: {
            businessComponents: tech.management.businessComponents.trTR,
        },
        statistics: statistics.inquiryStatistics.trTR,
        systemClassifyA: statistics.systemClassifyA.trTR,
        systemClassifyB: statistics.systemClassifyB.trTR,
        engineerStatistics: statistics.engineerStatistics.trTR,
        defectivePart: statistics.defectivePart.trTR,
        replyInquiryStatistics: statistics.replyInquiryStatistics.trTR,
        passRateStatistics: statistics.passRateStatistics.trTR,
        timelyResponseRateStatistics: statistics.timelyResponseRateStatistics.trTR,
        responseRateStatistics24Hour: statistics.responseRateStatistics24Hour.trTR,
        customsClearance135Rate: statistics.customsClearance135Rate.trTR,
        accumulatedClosureRate: statistics.accumulatedClosureRate.trTR,
        engineerAffiliatedOrganization: statistics.engineerAffiliatedOrganization.trTR,
        EngineerResponseRateReport: statistics.EngineerResponseRateReport.trTR,
    },
    validation: {
        common: {
            required: 'Bu alan zorunludur',
            numeric: 'Bu alan tam sayı olmalıdır',
            decimal: 'Bu alan ondalık sayı olmalıdır',
            positive_interger: 'Bu alan pozitif tam sayı olmalıdır',
            up_to_digits: ({ named }: Parameters<MessageFunction>[0]) =>
                `En fazla ${named('number')} basamaklı olabilir`,
            more_than_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `fazla olamaz${named('number')}harf`,
            max_bytes: ({ named }: Parameters<MessageFunction>[0]) =>
                `Maksimum karakter sayısını aşıyor(${named('number')})`,
            less_than_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `azami${named('number')}harf`,
            must_be_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `Olmalıdır${named('number')}harf`,
            greater_than: ({ named }: Parameters<MessageFunction>[0]) =>
                `Büyük olamaz${named('number')}`,
            less_than: ({ named }: Parameters<MessageFunction>[0]) => `Küçük olamaz${named('number')}`,
            up_to_2_decimal_places: 'En fazla 2 ondalık basamağa kadar',
            retain_up: 'En fazla 10 basamaklı pozitif tam sayı girebilir, en fazla 2 ondalık basamağı saklayabilirsiniz',
            retain_ups: 'En fazla 11 basamaklı pozitif tam sayı girebilir, en fazla 4 ondalık basamağı saklayabilirsiniz',
            input_number: 'Lütfen bir sayı girin',
            only_number_a: 'Yalnızca harf veya sayı girebilirsiniz',
            translate: 'Yalnızca harf olabilir',
            positive_integer: 'En az bir alan girin',
            at_least_one: 'En az bir tane doldurun',
        },
        tip: {
            incomplete_information: 'Bilgiler eksik dolduruldu',
        },
    },
    import: {
        template_file: 'Şablon Dosyası',
        browse: 'Gözat',
        download_template: 'Şablon İndir',
        no_file_selected: 'Dosya Seçilmedi',
        incorrect_file_type: 'Dosya Türü Hatalı',
        exceed: 'Aşıldı',
        upload_success: 'Yükleme Başarılı',
        upload_fail: 'Yükleme Başarısız',
        again_upload: 'Yalnızca jpg, png, bmp, gif formatındaki resim dosyalarını yükleyebilirsiniz. Lütfen yeniden yükleyin',
        attachmentUpload: 'Ek Dosya Yükleme',
        supportedFormats: 'Desteklenen Formatlar',
        preview: 'Önizleme',
    },
    export: {
        noDataToExport: 'Dışa aktarılacak veri bulunmamaktadır',
    },
    select: {
        all: 'Tümü',
    },
    checkbox: {
        select_all: 'Tümünü Seç',
    },
    dialog: {
        error_message: 'Hata Mesajı',
    },
    ymd: {
        year: 'Yıl',
        month: 'Ay',
        day: 'Gün',
        january: 'Jan',
        february: 'Feb',
        march: 'Mar',
        april: 'Apr',
        may: 'May',
        june: 'Jun',
        july: 'Jul',
        august: 'Aug',
        september: 'Sep',
        october: 'Oct',
        november: 'Nov',
        december: 'Dec',
    },
};
