export default {
    // 中
    zhCN: {
        serial_no: '序号',
        username: '用户名',
        name: '姓名',
        organization: '组织',
        sex: '性别',
        phone_number: '手机号',
        email: '邮箱',
        role: '角色',
        login_time: '启用状态',
        password: '密码',
        user_edit: '用户编辑',
        service_provider_name: '服务商名称',
        import_snw_users: '导入snw用户',
        export_snw_users: '导出snw用户',
        brand: '品牌',
        enable: '启用',
        disable: '禁用',
        userName: '用户姓名',
        validity_period: '有效期',
        mailbox_reset: '邮箱重置',
        new_email: '新邮箱',
        remaining_validity_period: '剩余有效期',
        permanently_valid: '永久有效',
        fileName: '文件名称',
        creation_time: '创建时间',
        taskStatus: '任务状态',
    },
    // 英
    enUS: {
        serial_no: 'Serial number',
        username: 'User name',
        name: 'Name',
        organization: 'Organization',
        sex: 'Gender',
        phone_number: 'Phone number',
        email: 'Email',
        role: 'Role',
        login_time: 'Enable status',
        password: 'Password',
        user_edit: 'User edit',
        service_provider_name: 'Service provider name',
        import_snw_users: 'Import snw users',
        export_snw_users: 'Export snw users',
        brand: 'Brand',
        enable: 'Enable',
        disable: 'Disable',
        userName: 'User Name',
        validity_period: 'Validity Period',
        mailbox_reset: 'Mailbox Reset',
        new_email: 'New Email',
        remaining_validity_period: 'Remaining Validity  Period',
        permanently_valid: 'Permanently Valid',
        fileName: 'File Name',
        creation_time: 'Creation Time',
        taskStatus: 'Task Status',
    },
    // 德
    deDE: {
        serial_no: 'Nr.',
        username: 'Benutzername',
        name: 'Name',
        organization: 'Organisation',
        sex: 'Geschlecht',
        phone_number: 'Telefonnummer',
        email: 'E-Mail',
        role: 'Rolle',
        login_time: 'Status aktivieren',
        password: 'Passwort',
        user_edit: 'Benutzer bearbeiten',
        service_provider_name: 'Name des Dienstleisters',
        import_snw_users: 'Snw-benutzer importieren',
        export_snw_users: 'Snw-benutzer exportieren',
        brand: 'Marke',
        enable: 'Enable',
        disable: 'Disable',
        userName: 'User Name',
        validity_period: 'Validity Period',
        mailbox_reset: 'Mailbox Reset',
        new_email: 'New Email',
        remaining_validity_period: 'Remaining Validity  Period',
        permanently_valid: 'Permanently Valid',
        fileName: 'Dateiname',
        creation_time: 'Erstellungszeit',
        taskStatus: 'Aufgabenstatus',
    },
    // 法
    frFR: {
        serial_no: 'No',
        username: "Nom d'utilisateur",
        name: 'Nom',
        organization: 'Organisation',
        sex: 'Genre',
        phone_number: 'Numéro de téléphone',
        email: 'E-mail',
        role: 'Rôle',
        login_time: 'Statut actif',
        password: 'Mot de passe',
        user_edit: "Modifier l'utilisateur",
        service_provider_name: 'Nom du prestataire',
        import_snw_users: 'Importer un utilisateur snw',
        export_snw_users: 'Exporter un utilisateur snw',
        brand: 'Marque',
        enable: 'Enable',
        disable: 'Disable',
        userName: 'User Name',
        validity_period: 'Validity Period',
        mailbox_reset: 'Mailbox Reset',
        new_email: 'New Email',
        remaining_validity_period: 'Remaining Validity  Period',
        permanently_valid: 'Permanently Valid',
        fileName: 'Nom du fichier',
        creation_time: 'Date de création',
        taskStatus: 'État de la tâche',
    },
    // 意大利
    itIT: {
        serial_no: 'No',
        username: 'Nome Utente',
        name: 'Nome',
        organization: 'Organizzazione',
        sex: 'Sesso',
        phone_number: 'Numero di Telefono',
        email: 'Email',
        role: 'Ruolo',
        login_time: 'Abilita stato',
        password: 'Password',
        user_edit: 'Modifica Utente',
        service_provider_name: 'Nome del fornitore di servizi',
        import_snw_users: 'Importa utenti snw',
        export_snw_users: 'Esporta utenti snw',
        brand: 'Marca',
        enable: 'Enable',
        disable: 'Disable',
        userName: 'User Name',
        validity_period: 'Validity Period',
        mailbox_reset: 'Mailbox Reset',
        new_email: 'New Email',
        remaining_validity_period: 'Remaining Validity  Period',
        permanently_valid: 'Permanently Valid',
        fileName: 'Nome del file',
        creation_time: 'Data di creazione',
        taskStatus: 'Stato del compito',
    },
    // 荷兰
    nlNL: {
        serial_no: 'Nr.',
        username: 'Gebruikersnaam',
        name: 'Naam',
        organization: 'organisatie',
        sex: 'Geslacht',
        phone_number: 'Telefoonnummer',
        email: 'E-mail',
        role: 'Rol',
        login_time: 'Status inschakelen',
        password: 'Wachtwoord',
        user_edit: 'Gebruiker bewerken',
        service_provider_name: 'Naam dienstverlener',
        import_snw_users: 'Snw-gebruikers importeren',
        export_snw_users: 'Snw-gebruikers exporteren',
        brand: 'Merk',
        enable: 'Enable',
        disable: 'Disable',
        userName: 'User Name',
        validity_period: 'Validity Period',
        mailbox_reset: 'Mailbox Reset',
        new_email: 'New Email',
        remaining_validity_period: 'Remaining Validity  Period',
        permanently_valid: 'Permanently Valid',
        fileName: 'Bestandsnaam',
        creation_time: 'Aanmaaktijd',
        taskStatus: 'Taakstatus',
    },
    // 越南
    viVN: {
        serial_no: 'Số sê-ri',
        username: 'Tên người dùng',
        name: 'Tên',
        organization: 'Lịch',
        sex: 'Giới tính',
        phone_number: 'Số điện thoại',
        email: 'Email',
        role: 'Vai trò',
        login_time: 'Trạng thái kích hoạt',
        password: 'Mật khẩu',
        user_edit: 'Chỉnh sửa người dùng',
        service_provider_name: 'Tên nhà cung cấp dịch vụ',
        import_snw_users: 'Nhập người dùng snw',
        export_snw_users: 'Xuất người dùng snw',
        brand: 'Thương hiệu',
        enable: 'Enable',
        disable: 'Disable',
        userName: 'User Name',
        validity_period: 'Validity Period',
        mailbox_reset: 'Mailbox Reset',
        new_email: 'New Email',
        remaining_validity_period: 'Remaining Validity  Period',
        permanently_valid: 'Permanently Valid',
        fileName: 'Tên tệp',
        creation_time: 'Thời gian tạo',
        taskStatus: 'Trạng thái nhiệm vụ',
    },
    // 西班牙
    esES: {
        serial_no: 'Núm. de serie',
        username: 'Nombre de usuario',
        name: 'Nombre',
        organization: 'Organización',
        sex: 'Género',
        phone_number: 'Número de teléfono',
        email: 'Correo electrónico',
        role: 'Rol',
        login_time: 'Estado de activación',
        password: 'Contraseña',
        user_edit: 'Editar usuario',
        service_provider_name: 'Nombre del proveedor de servicios',
        import_snw_users: 'Importar usuarios de snw',
        export_snw_users: 'Exportar usuarios de snw',
        brand: 'Marca',
        enable: 'Enable',
        disable: 'Disable',
        userName: 'User Name',
        validity_period: 'Validity Period',
        mailbox_reset: 'Mailbox Reset',
        new_email: 'New Email',
        remaining_validity_period: 'Remaining Validity  Period',
        permanently_valid: 'Permanently Valid',
        fileName: 'Nombre del archivo',
        creation_time: 'Fecha de creación',
        taskStatus: 'Estado de la tarea',
    },
    // 葡萄牙
    ptBR: {
        serial_no: 'Número de série',
        username: 'Nome de usuário',
        name: 'Nome',
        organization: 'Organização',
        sex: 'Sexo',
        phone_number: 'Número de telefone',
        email: 'E-mail',
        role: 'Função',
        login_time: 'Tempo de login',
        password: 'Senha',
        user_edit: 'Editar usuário',
        service_provider_name: 'Nome do provedor de serviços',
        import_snw_users: 'Importar usuários SNW',
        export_snw_users: 'Exportar usuários SNW',
        brand: 'Marca',
        enable: 'Enable',
        disable: 'Disable',
        userName: 'User Name',
        validity_period: 'Validity Period',
        mailbox_reset: 'Mailbox Reset',
        new_email: 'New Email',
        remaining_validity_period: 'Remaining Validity  Period',
        permanently_valid: 'Permanently Valid',
        fileName: 'Nome do arquivo',
        creation_time: 'Data de criação',
        taskStatus: 'Status da tarefa',
    },
    // 波兰
    plPL: {
        serial_no: 'Numer seryjny',
        username: 'Nazwa użytkownika',
        name: 'Imię i nazwisko',
        organization: 'Organizacja',
        sex: 'Płeć',
        phone_number: 'Numer telefonu',
        email: 'Email',
        role: 'Rola',
        login_time: 'status aktywcji',
        password: 'Hasło',
        user_edit: 'Edycja użytkownika',
        service_provider_name: 'Nazwa usługodawcy',
        import_snw_users: 'Importuj użytkowników SNW',
        export_snw_users: 'Eksportuj użytkowników SNW',
        brand: 'Marka',
        enable: 'Enable',
        disable: 'Disable',
        userName: 'User Name',
        validity_period: 'Validity Period',
        mailbox_reset: 'Mailbox Reset',
        new_email: 'New Email',
        remaining_validity_period: 'Remaining Validity  Period',
        permanently_valid: 'Permanently Valid',
        fileName: 'Nazwa pliku',
        creation_time: 'Czas utworzenia',
        taskStatus: 'Status zadania',
    },
    // 土耳其
    trTR: {
        serial_no: 'Nr',
        username: ' Kullanıcı adı',
        name: 'İsim',
        organization: 'Düzenleme',
        sex: 'Cinsiyet',
        phone_number: 'Telefon numarası',
        email: 'E-posta',
        role: 'Rol',
        login_time: 'Etkin durum',
        password: 'Parola',
        user_edit: 'Kullanıcıyı Düzenle',
        service_provider_name: 'Hizmet verici adı',
        import_snw_users: 'İçeri snw kullanıcı',
        export_snw_users: 'türev snw kullanıcı',
        brand: 'Marka',
        enable: 'Enable',
        disable: 'Disable',
        userName: 'User Name',
        validity_period: 'Validity Period',
        mailbox_reset: 'Mailbox Reset',
        new_email: 'New Email',
        remaining_validity_period: 'Remaining Validity  Period',
        permanently_valid: 'Permanently Valid',
        fileName: 'Dosya adı',
        creation_time: 'Oluşturulma zamanı',
        taskStatus: 'Görev durumu',
    },
    // 捷克语
    csCZ: {
        serial_no: 'Sériové číslo',
        username: 'Uživatelské jméno',
        name: 'Název',
        organization: 'Organizace',
        sex: 'Pohlaví',
        phone_number: 'Telefonní číslo',
        email: 'E-mailem',
        role: 'Role',
        login_time: 'Povolit stav',
        password: 'Heslo',
        user_edit: 'Úprav uživatele',
        service_provider_name: 'Jméno poskytovatele služeb',
        import_snw_users: 'Importujte uživatele snw',
        export_snw_users: 'Exportujte uživatele snw',
        brand: 'značka',
        enable: 'Enable',
        disable: 'Disable',
        userName: 'User Name',
        validity_period: 'Validity Period',
        mailbox_reset: 'Mailbox Reset',
        new_email: 'New Email',
        remaining_validity_period: 'Remaining Validity  Period',
        permanently_valid: 'Permanently Valid',
        fileName: 'Název souboru',
        creation_time: 'Čas vytvoření',
        taskStatus: 'Stav úkolu',
    },
    // 匈牙利语
    huHU: {
        serial_no: 'Sorozatszám',
        username: 'Felhasználónév',
        name: 'Név',
        organization: 'Szervezet',
        sex: 'Nem',
        phone_number: 'Telefonszám',
        email: 'E-mail',
        role: 'Szerep',
        login_time: 'Engedélyezési állapot',
        password: 'Jelszó',
        user_edit: 'Felhasználói szerkesztés',
        service_provider_name: 'Szolgáltató neve',
        import_snw_users: 'SNW-felhasználók importálása',
        export_snw_users: 'SNW-felhasználók exportálása',
        brand: 'Márka',
        enable: 'Enable',
        disable: 'Disable',
        userName: 'User Name',
        validity_period: 'Validity Period',
        mailbox_reset: 'Mailbox Reset',
        new_email: 'New Email',
        remaining_validity_period: 'Remaining Validity  Period',
        permanently_valid: 'Permanently Valid',
        fileName: 'Fájlnév',
        creation_time: 'Létrehozás ideje',
        taskStatus: 'Feladat állapota',
    },
    // 俄罗斯语
    ruRU: {
        serial_no: 'Номер',
        username: 'Имя пользователя',
        name: 'Имя',
        organization: 'Организация',
        sex: 'Пол',
        phone_number: 'Мобильный телефон',
        email: 'Электронная почта',
        role: 'Роль',
        login_time: 'Статус активации',
        password: 'Пароль',
        user_edit: 'Редактирование пользователя',
        service_provider_name: 'Название поставщика услуг',
        import_snw_users: 'Импорт пользователей SNW',
        export_snw_users: 'Экспорт пользователей SNW',
        brand: 'Бренд',
        enable: 'Enable',
        disable: 'Disable',
        userName: 'User Name',
        validity_period: 'Validity Period',
        mailbox_reset: 'Mailbox Reset',
        new_email: 'New Email',
        remaining_validity_period: 'Remaining Validity  Period',
        permanently_valid: 'Permanently Valid',
        fileName: 'Имя файла',
        creation_time: 'Время создания',
        taskStatus: 'Статус задачи',
    },
};
