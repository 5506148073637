import { useResponseHandler } from '~/utils/http/handler';
import { useTypedI18n } from '~/composables/i18n';
import { baseUrl, getRequest } from '~/utils/http/request';
import { useVDR } from 'v-demi-request';
import { paramsFilter } from '~/utils/toolFunction';
import { useTableData } from './table';

export const useRequests = () => {
    const { handler, vdrHandler } = useResponseHandler();
    const { locale } = useTypedI18n();
    const { tableData, totalPages, total } = useTableData();
    return {
        caseExportTaskList: (param: any) => {
            const { loading, onSuccess, send } = useVDR(
                () => `${baseUrl}/snw/vinBrochure/getCaseExportTaskList`,
                (key: string) => getRequest(key, paramsFilter(param.value)),
            );
            onSuccess((param) => {
                handler(param, {
                    onSuccess: ({ res }) => {
                        res.records.forEach((item) => {
                            const proxy = new Proxy(item, {
                                set(target, key, value, receiver) {
                                    target[key] = value;
                                    return true;
                                },
                            });
                            proxy.loading = false;
                        });
                        tableData.value = res.records;
                        totalPages.value = res.pages;
                        total.value = res.total;
                    },
                });
            });
            return {
                loading,
                tableData,
                refreshTableData: send,
                totalPages,
                total,
            };
        },
    };
};
