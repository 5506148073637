// src/directives/watermark.ts
import type { DirectiveBinding } from 'vue';

// 创建水印，接收对象形式的文字内容，并支持旋转和间距
const addWatermark = (
    textObj: { username: string; realName: string },
    parentNode: HTMLElement,
): HTMLDivElement => {
    const canvas = document.createElement('canvas');
    canvas.width = 200; // 调整宽度以容纳旋转
    canvas.height = 150; // 调整高度以容纳旋转

    const ctx = canvas.getContext('2d');
    if (ctx) {
        // 清除之前的绘制内容
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        ctx.translate(canvas.width / 2, canvas.height / 2); // 将绘制原点移到中心
        ctx.rotate((-30 * Math.PI) / 180);

        ctx.font = '20px Microsoft Yahei';
        ctx.fillStyle = 'rgba(200, 200, 200, 0.50)'; // 半透明效果
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';

        const textArray = [textObj.username, textObj.realName];
        const lineHeight = 20; // 控制每行的高度
        const lineGap = 10; // 控制行间距

        textArray.forEach((text, index) => {
            ctx.fillText(text, 0, (lineHeight + lineGap) * index); // 绘制每行文本并设置间距
        });
    }

    // 创建水印 div
    const watermarkDiv = document.createElement('div');
    watermarkDiv.style.position = 'absolute'; // 更改为 absolute
    watermarkDiv.style.top = '0';
    watermarkDiv.style.left = '0';
    watermarkDiv.style.width = '100%';
    watermarkDiv.style.height = '100%';
    watermarkDiv.style.pointerEvents = 'none';
    watermarkDiv.style.zIndex = '1999'; // 确保水印位于最上层
    watermarkDiv.style.background = `url(${canvas.toDataURL('image/png')}) left top repeat`;
    parentNode.appendChild(watermarkDiv);
    return watermarkDiv;
};

// 定义水印指令
export const vWatermark = {
    mounted(el: HTMLElement, binding: DirectiveBinding) {
        // 为 el 添加 watermarkDiv，接收对象参数
        (el as any).watermarkDiv = addWatermark(
            binding.value || { username: 'SMIL', realName: 'SMIL' },
            el,
        );
    },
    beforeUnmount(el: HTMLElement) {
        console.log('Watermark directive unmounted');
        if ((el as any).watermarkDiv) {
            (el as any).watermarkDiv.remove();
        }
    },
};
