import type { MessageFunction } from 'vue-i18n';
import homepage from './pages/homepage/index';
import tech from './pages/tech/index';
import statistics from './pages/statistics/index';
import Info from './pages/info/index';
import system from './pages/system/index';
// 俄罗斯
export default {
    license: {
        powered_by: 'Powered by SMIL',
    },
    auth: {
        login: 'Войти',
        username: 'Имя пользователя',
        username_placeholder: 'Введите имя пользователя',
        password: 'Пароль',
        password_placeholder: 'Введите пароль',
        code: 'Код проверки',
        code_placeholder: 'Введите код проверки',
        logout: 'Выйти',
        logout_success: 'Выход выполнен успешно',
        day: 'День',
        password_expiration: 'Пароль истек, пожалуйста, измените его',
        expire: 'Оставшееся до истечения срока действия пароля',
        conseils:
            'Пароль должен содержать заглавные буквы, строчные буквы, цифры и специальные символы ({msg}), и длина пароля должна быть от 8 до 16 символов',
        brand_Selection: 'Выбор бренда',
        unread_notices: 'Непрочитанные уведомления',
        new_cases: 'Новые дела ',
        unanswered_cases: 'Неотвеченные дела',
        overdue_cases: 'Просроченные дела',
    },
    button: {
        query: 'Поиск',
        editing_position: 'Редактирование позиции',
        show_theme_editor: 'Показать/Скрыть редактор темы',
        change_password: 'Изменить пароль',
        old_password: 'Старый пароль',
        new_password: 'Новый пароль',
        confirm_password: 'Подтвердите пароль',
        button: 'Кнопка',
        about: 'О программе',
        back: 'Назад',
        go: 'Перейти',
        home: 'Домой',
        confirm: 'Подтвердить',
        modify: 'Изменить',
        edit: 'Редактировать',
        new: 'Добавить',
        cancel: 'Отмена',
        close: 'Закрыто',
        search: 'Поиск',
        reset: 'Сброс',
        delete: 'Удалить',
        add: 'Добавить',
        collapse: 'Свернуть',
        expand: 'Раскрыть',
        download_template: 'Скачать шаблон',
        batch_import: 'Пакетный импорт',
        import: 'Импорт',
        export: 'Экспорт',
        export_fail: 'Ошибка экспорта',
        detailed: 'Подробности',
        details: 'Детали',
        download: 'Скачать',
        toView: 'Просмотреть',
        toViews: 'Просмотреть изображение',
        uploadAttachments: 'Загрузить',
        selectFile: 'Выбрать файл',
        fillingInstructions: 'Инструкции по заполнению',
        release: 'Выпуск',
        release_recall: 'release_recall',
        save: 'Сохранить',
        send: 'Отправить',
        tips: 'Советы',
        send_back: 'Вернуться',
        examine: 'Проверить',
        void: 'Неверно',
        more: 'Еще',
        refresh: 'Обновить',
        selects: 'Пожалуйста, выберите',
        serial_number: 'Серийный номер',
        upload_progress: 'Прогресс загрузки',
        invalid: 'Неверно',
        daily_record: 'Ежедневный отчет',
        operation_type: 'Тип операции',
        field_name: 'Поле',
        edit_role: 'Редактировать роль',
        user_role: 'Роль пользователя',
        assignment_role: 'Роль',
        post: 'Должность',
        sending: 'Отправка',
        reset_password: 'Сброс пароля',
        display_or_not: 'Отображать или нет',
        document: 'Документ',
        specificTemplates: 'Специфические шаблоны',
        transferOrder: 'Передача заказа',
        forwarding: 'Пересылка',
        one_click_notification: 'Уведомление в один клик',
        english_export: 'English Export',
        oneclick_download: 'Загрузка в один клик',
    },
    radio: {
        enabled: 'Включено',
        disabled: 'Отключено',
        catalogue: 'Каталог',
        individual: 'Индивидуальный',
        all: 'Все',
        yes: 'Да',
        no: 'Нет',
    },
    dropdown: {
        close_others: 'Закрыть другие',
        close_right: 'Закрыть справа',
        close_left: 'Закрыть слева',
    },
    table: {
        action: 'Действие',
        explain: 'Описание',
        fileName: 'Имя вложения',
        size: 'Размер (МБ)',
        uploader: 'Загрузил',
        uploadTime: 'Время загрузки',
        system: 'Система',
        caption: 'Подпись',
        categorization: 'Категоризация',
        link: 'Ссылка',
        forwarding_time: 'Время пересылки',
        forwarder: 'Пересылатель',
        send_to: 'Отправить',
        mode: 'Режим',
    },
    pagination: {
        pages: ({ named }: Parameters<MessageFunction>[0]) => `${named('number')}/страница`,
        total: ({ named }: Parameters<MessageFunction>[0]) => `всего ${named('number')} запись`,
        vinCount: ({ named }: Parameters<MessageFunction>[0]) => `всего ${named('number')} автомобиль`,
    },
    confirm: {
        confirm_deletion: 'Подтвердить удаление?',
        confirm_void: 'Подтвердить аннулирование?',
        confirm_release: 'Подтвердить выпуск?',
        ack: 'Подтвердить',
        cancel: 'Отмена',
        non_records: 'Пожалуйста, выберите хотя бы одну запись',
    },
    request: {
        failed_placeholder: 'Запрос не удался, ошибка на сервере',
        success_placeholder: 'Запрос выполнен успешно',
        auth: {
            login_success: 'Вход выполнен успешно',
        },
        error: {
            '404': 'URL запроса не найден',
            '403': 'Доступ запрещен',
        },
    },
    page: {
        global: {
            page_search: 'Поиск страницы',
        },
        error: {
            not_found: 'Не найдено',
            not_found_des: 'Здесь ничего нет',
            auth_failed: 'Ошибка авторизации',
            auth_failed_des: 'Эта страница недоступна',
            file_format_error: 'Ошибка формата файла!',
            preview_failed: 'Просмотр не удался! Файл аномален!',
        },
        homepage: {
            homepage: homepage.homepage.ruRU,
        },
        system: {
            menu: system.menu.ruRU,
            dict: system.dict.ruRU,
            dept: system.dept.ruRU,
            role: system.role.ruRU,
            user: system.user.ruRU,
        },
        info: {
            announcement: Info.announcement.announcement.ruRU,
            document: Info.document.document.ruRU,
        },
        tech: {
            quality: {
                problemTracking: tech.quality.problemTracking.ruRU,
                recallActivity: tech.quality.recallActivity.ruRU,
                abnormalClaimTracking: tech.quality.abnormalClaimTracking.ruRU,
            },
            management: {
                inquiryOd: tech.management.inquiry.ruRU,
                permission: tech.management.permission.ruRU,
                onSiteSupportHq: tech.management.onSiteSupport.ruRU,
                progHistoryHq: tech.management.progHistory.ruRU,
                salesRecordHq: tech.management.salesRecord.ruRU,
                vehicleInfoHq: tech.management.vehicleInfo.ruRU,
                claimHq: tech.management.claim.ruRU,
                filterHq: tech.management.filter.ruRU,
                newCarlssue: tech.management.newCarIssue.ruRU,
                newCarDefinition: tech.management.newCarDefinition.ruRU,
                vehicleProductionInfo: tech.management.vehicleProductionInfo.ruRU,
                programmingHistoryInformationQuery:
                    tech.management.programmingHistoryInformationQuery.ruRU,
                ecuControllerInformationQuery: tech.management.ecuControllerInformationQuery.ruRU,
            },
        },
        components: {
            businessComponents: tech.management.businessComponents.ruRU,
        },
        statistics: statistics.inquiryStatistics.ruRU,
        systemClassifyA: statistics.systemClassifyA.ruRU,
        systemClassifyB: statistics.systemClassifyB.ruRU,
        engineerStatistics: statistics.engineerStatistics.ruRU,
        defectivePart: statistics.defectivePart.ruRU,
        replyInquiryStatistics: statistics.replyInquiryStatistics.ruRU,
        passRateStatistics: statistics.passRateStatistics.ruRU,
        timelyResponseRateStatistics: statistics.timelyResponseRateStatistics.ruRU,
        responseRateStatistics24Hour: statistics.responseRateStatistics24Hour.ruRU,
        customsClearance135Rate: statistics.customsClearance135Rate.ruRU,
        accumulatedClosureRate: statistics.accumulatedClosureRate.ruRU,
        engineerAffiliatedOrganization: statistics.engineerAffiliatedOrganization.ruRU,
        EngineerResponseRateReport: statistics.EngineerResponseRateReport.ruRU,
    },
    validation: {
        common: {
            required: 'Требуется элемент',
            numeric: 'Должно быть целым числом',
            decimal: 'Должно быть числом',
            positive_interger: 'Должно быть положительным целым числом',
            up_to_digits: ({ named }: Parameters<MessageFunction>[0]) =>
                `Не более ${named('number')} цифр`,
            more_than_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `Более${named('number')}символов`,
            max_bytes: ({ named }: Parameters<MessageFunction>[0]) =>
                `Превышает максимальное количество байтов(${named('number')})`,
            less_than_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `Менее${named('number')}символов`,
            must_be_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `Должно быть${named('number')}символов`,
            greater_than: ({ named }: Parameters<MessageFunction>[0]) =>
                `Больше чем${named('number')}`,
            less_than: ({ named }: Parameters<MessageFunction>[0]) => `Меньше чем${named('number')}`,
            up_to_2_decimal_places: 'До 2 десятичных знаков',
            retain_up: 'Введите до 10 положительных целых чисел и до 4 десятичных знаков',
            retain_ups: 'Введите до 11 положительных целых чисел и до 4 десятичных знаков',
            input_number: 'Пожалуйста, введите число',
            only_number_a: 'Можно ввести только буквы или цифры',
            translate: 'Только буквы',
            positive_integer: 'Введите хотя бы одно поле',
            at_least_one: 'Заполните хотя бы одно',
        },
        tip: {
            incomplete_information: 'Неполная информация',
        },
    },
    import: {
        template_file: 'Шаблон файла',
        browse: 'Обзор',
        download_template: 'Скачать шаблон',
        no_file_selected: 'Файл не выбран',
        incorrect_file_type: 'Неправильный тип файла',
        exceed: 'Превышает',
        upload_success: 'Загрузка успешна',
        upload_fail: 'Загрузка не удалась',
        again_upload: 'Можно загружать только изображения в форматах jpg, png, bmp или gif. Пожалуйста, загрузите снова',
        attachmentUpload: 'Загрузка вложения',
        supportedFormats: 'Поддерживаемые форматы',
        preview: 'Просмотр',
    },
    export: {
        noDataToExport: 'Нет данных для экспорта',
    },
    select: {
        all: 'Все',
    },
    checkbox: {
        select_all: 'Выбрать все',
    },
    dialog: {
        error_message: 'Сообщение об ошибке',
    },
    ymd: {
        year: 'Год',
        month: 'Месяц',
        day: 'День',
        january: 'Янв.',
        february: 'Фев.',
        march: 'Март',
        april: 'Апр.',
        may: 'Май',
        june: 'Июн.',
        july: 'Июл.',
        august: 'Авг.',
        september: 'Сен.',
        october: 'Окт.',
        november: 'Нояб.',
        december: 'Дек.',
    },
};
