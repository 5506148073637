import type { MessageFunction } from 'vue-i18n';
import homepage from './pages/homepage/index';
import tech from './pages/tech/index';
import statistics from './pages/statistics/index';
import Info from './pages/info/index';
import system from './pages/system/index';
// 中
export default {
    license: {
        powered_by: '上汽国际 {\'|\'} 版权所有',
    },
    auth: {
        login: '登录',
        username: '用户名',
        username_placeholder: '请输入用户名',
        password: '密码',
        password_placeholder: '请输入密码',
        code: '验证码',
        code_placeholder: '请输入验证码',
        logout: '注销',
        logout_success: '注销成功',
        day: '天',
        password_expiration: '密码已到期请修改',
        expire: '距离密码到期还剩',
        conseils: '必须包含大写字母、小写字母、数字、特殊符号（{msg}），密码长度在 8-16 位',
        brand_Selection: '品牌选择',
        unread_notices: '未读公告',
        new_cases: '新案例 ',
        unanswered_cases: '未回复案例',
        overdue_cases: '超期案例',
    },
    button: {
        query: '查询',
        editing_position: '编辑岗位',
        show_theme_editor: '显示/隐藏主题编辑器',
        change_password: '修改密码',
        old_password: '旧密码',
        new_password: '新密码',
        confirm_password: '确认密码',
        button: '按钮',
        about: '关于',
        back: '返回',
        go: '确定',
        home: '首页',
        confirm: '确定',
        modify: '修改',
        edit: '编辑',
        new: '新增',
        cancel: '取消',
        close: '关闭',
        search: '搜索',
        reset: '重置',
        delete: '删除',
        add: '新增',
        collapse: '收起',
        expand: '展开',
        download_template: '下载模板',
        batch_import: '批量导入',
        import: '导入',
        export: '导出',
        export_fail: '导入失败',
        detailed: '明细',
        details: '详情',
        download: '下载',
        toView: '查看',
        toViews: '查看图片',
        uploadAttachments: '上传附件',
        selectFile: '选择文件',
        fillingInstructions: '填写说明',
        release: '发布',
        release_recall: '发布撤回',
        save: '保存',
        send: '发送',
        tips: '提示',
        send_back: '退回',
        examine: '审核',
        void: '作废',
        more: '更多',
        refresh: '刷新',
        selects: '请选择',
        serial_number: '序号',
        upload_progress: '上传进度',
        invalid: '作废',
        daily_record: '日志',
        operation_type: '操作类型',
        field_name: '字段名',
        edit_role: '编辑角色',
        user_role: '选择角色',
        assignment_role: '角色',
        post: '岗位',
        sending: '发送中',
        reset_password: '重置密码',
        display_or_not: '是否显示',
        document: '文档',
        specificTemplates: '特定模板',
        transferOrder: '转单',
        forwarding: '转发',
        one_click_notification: '一键通知',
        english_export: '英文导出',
        oneclick_download: '一键下载',
    },
    radio: {
        enabled: '启用',
        disabled: '停用',
        catalogue: '目录',
        individual: '个体',
        all: '全部',
        yes: '是',
        no: '否',
    },
    dropdown: {
        close_others: '关闭其他',
        close_right: '关闭右侧',
        close_left: '关闭左侧',
    },
    table: {
        action: '操作',
        explain: '说明',
        fileName: '附件名称',
        size: '大小(MB)',
        uploader: '上传人',
        uploadTime: '上传时间',
        system: '系统',
        caption: '标题',
        categorization: '分类',
        link: '链接',
        forwarding_time: '转发时间',
        forwarder: '转发人',
        send_to: '发送对象',
        mode: '模式',
    },
    pagination: {
        pages: ({ named }: Parameters<MessageFunction>[0]) => `${named('number')}/页`,
        total: ({ named }: Parameters<MessageFunction>[0]) => `共 ${named('number')} 条数据`,
        vinCount: ({ named }: Parameters<MessageFunction>[0]) => `共 ${named('number')} 辆车`,
    },
    confirm: {
        confirm_deletion: '确认删除?',
        confirm_void: '确认作废?',
        confirm_release: '确认发布?',
        ack: '确认',
        cancel: '取消',
        non_records: '请至少选择一条数据',
    },
    request: {
        failed_placeholder: '请求失败，服务端错误',
        success_placeholder: '请求成功',
        auth: {
            login_success: '登录成功',
        },
        error: {
            '404': '请求路径不存在',
            '403': '权限不足',
        },
    },
    page: {
        global: {
            page_search: '页面搜索',
        },
        error: {
            not_found: '未找到页面',
            not_found_des: '这里没有任何东西',
            auth_failed: '权限不足',
            auth_failed_des: '此页面禁止访问',
            file_format_error: '文件格式异常！',
            preview_failed: '预览失败！文件异常！',
        },
        homepage: {
            homepage: homepage.homepage.zhCN,
        },
        system: {
            menu: system.menu.zhCN,
            dict: system.dict.zhCN,
            dept: system.dept.zhCN,
            role: system.role.zhCN,
            user: system.user.zhCN,
        },
        info: {
            announcement: Info.announcement.announcement.zhCN,
            document: Info.document.document.zhCN,
        },
        tech: {
            quality: {
                problemTracking: tech.quality.problemTracking.zhCN,
                recallActivity: tech.quality.recallActivity.zhCN,
                abnormalClaimTracking: tech.quality.abnormalClaimTracking.zhCN,
            },
            management: {
                inquiryOd: tech.management.inquiry.zhCN,
                permission: tech.management.permission.zhCN,
                onSiteSupportHq: tech.management.onSiteSupport.zhCN,
                progHistoryHq: tech.management.progHistory.zhCN,
                salesRecordHq: tech.management.salesRecord.zhCN,
                vehicleInfoHq: tech.management.vehicleInfo.zhCN,
                claimHq: tech.management.claim.zhCN,
                filterHq: tech.management.filter.zhCN,
                newCarlssue: tech.management.newCarIssue.zhCN,
                newCarDefinition: tech.management.newCarDefinition.zhCN,
                vehicleProductionInfo: tech.management.vehicleProductionInfo.zhCN,
                programmingHistoryInformationQuery:
                    tech.management.programmingHistoryInformationQuery.zhCN,
                ecuControllerInformationQuery: tech.management.ecuControllerInformationQuery.zhCN,
            },
        },
        components: {
            businessComponents: tech.management.businessComponents.zhCN,
        },
        statistics: statistics.inquiryStatistics.zhCN,
        systemClassifyA: statistics.systemClassifyA.zhCN,
        systemClassifyB: statistics.systemClassifyB.zhCN,
        engineerStatistics: statistics.engineerStatistics.zhCN,
        defectivePart: statistics.defectivePart.zhCN,
        replyInquiryStatistics: statistics.replyInquiryStatistics.zhCN,
        passRateStatistics: statistics.passRateStatistics.zhCN,
        timelyResponseRateStatistics: statistics.timelyResponseRateStatistics.zhCN,
        responseRateStatistics24Hour: statistics.responseRateStatistics24Hour.zhCN,
        customsClearance135Rate: statistics.customsClearance135Rate.zhCN,
        accumulatedClosureRate: statistics.accumulatedClosureRate.zhCN,
        engineerAffiliatedOrganization: statistics.engineerAffiliatedOrganization.zhCN,
        EngineerResponseRateReport: statistics.EngineerResponseRateReport.zhCN,
    },
    validation: {
        common: {
            required: '此项必填',
            numeric: '必须为整数数字',
            decimal: '必须为数字',
            positive_interger: '必须为正整数',
            up_to_digits: ({ named }: Parameters<MessageFunction>[0]) =>
                `最多为${named('number')}位数`,
            more_than_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `不能超过${named('number')}字`,
            max_bytes: ({ named }: Parameters<MessageFunction>[0]) =>
                `超过最大字符数(${named('number')})`,
            less_than_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `不少于${named('number')}字`,
            must_be_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `必须为${named('number')}字`,
            greater_than: ({ named }: Parameters<MessageFunction>[0]) =>
                `不能大于${named('number')}`,
            less_than: ({ named }: Parameters<MessageFunction>[0]) => `不能小于${named('number')}`,
            up_to_2_decimal_places: '最多为2位小数',
            retain_up: '最多输入10位正整数，最多保留2位小数',
            retain_ups: '最多输入11位正整数，最多保留4位小数',
            input_number: '请输入数字',
            only_number_a: '只能输入字母或数字',
            translate: '只能为字母',
            positive_integer: '至少输入一个字段',
            at_least_one: '至少填写一个',
        },
        tip: {
            incomplete_information: '信息未填写完整',
        },
    },
    import: {
        template_file: '模板文件',
        browse: '浏览',
        download_template: '下载模板',
        no_file_selected: '未选择文件',
        incorrect_file_type: '文件类型不正确',
        exceed: '超出',
        upload_success: '上传成功',
        upload_fail: '上传失败',
        again_upload: '只能上传 jpg、png、bmp、gif 格式的图片文件，请重新上传',
        attachmentUpload: '附件上传',
        supportedFormats: '支持格式',
        preview: '预览',
    },
    export: {
        noDataToExport: '没有可导出数据',
    },
    select: {
        all: '全部',
    },
    checkbox: {
        select_all: '全选',
    },
    dialog: {
        error_message: '错误信息',
    },
    ymd: {
        year: '年',
        month: '月',
        day: '日',
        january: '1月',
        february: '2月',
        march: '3月',
        april: '4月',
        may: '5月',
        june: '6月',
        july: '7月',
        august: '8月',
        september: '9月',
        october: '10月',
        november: '11月',
        december: '12月',
    },
};
