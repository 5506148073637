export default {
    // 中
    zhCN: {
        area: '区域',
        monthly_case_count: '月度案件数', // 月度案件数
        monthly_closed: '月度已关闭', // 月度已关闭
        monthly_closure_rate: '月度关闭率', // 月度关闭率
        accumulated_closure_rate: '累计关闭率', // 累计关闭率
        case_count_closure_rate: '案例数与关闭率',
        Key_market_technical_support_performance_statistics: '重点市场技术支持表现统计',
        Accumulated_case_count: '累计案件数',
        Accumulated_closure_count: '累计关闭数',
        month_case_count: '月案件数',
        month_closure_count: '月关闭数',
        five_Day_closure_rate: '5天关闭率',
        five_Day_closure_count: '5天关闭数',
        response_count_24hour: '24小时答复数',
        response_rate_24hour: '24小时回复率',
        month_response_rate_24hour: '24小时回复率',
        form_type: '表单类型',
        repeat_multiple_choice_msg: '月份与区域不可同时多选！',
        day_closure_rate: '天关闭率',
        months_case_count: '月度案件数',
        individually: '个体',
        full: '全部',
        received: '收到',
        report: '上报',
        regional_abbreviations: '区域简称',
        name_of_organisation: '组织名称',
        form_of_documents: '单据形式',
        statistical_coverage: '统计范围',
        requiredMsg: '必填信息未填写!',
    },
    // 英
    enUS: {
        area: 'Area',
        monthly_case_count: 'Monthly Case Count', // 月度案件数
        monthly_closed: 'Monthly Closed', // 月度已关闭
        monthly_closure_rate: 'Monthly Closure Rate', // 月度关闭率
        accumulated_closure_rate: 'Accumulated Closure Rate', // 累计关闭率
        case_count_closure_rate: 'Case Count and Closure Rate',
        Key_market_technical_support_performance_statistics:
            'Key market technical support performance statistics',
        Accumulated_case_count: 'Accumulated case count',
        Accumulated_closure_count: 'Accumulated closure count',
        month_case_count: 'month case count',
        month_closure_count: 'month closure count',
        five_Day_closure_rate: 'Five day closure rate',
        five_Day_closure_count: 'Five day closure count',
        response_count_24hour: 'response count 24 hour',
        response_rate_24hour: 'response rate 24 hour',
        month_response_rate_24hour: 'response rate 24 hour',
        form_type: 'Form Type',
        repeat_multiple_choice_msg: 'Month and region cannot be selected at the same time!',
        day_closure_rate: 'day closure rate',
        months_case_count: 'number of cases per month',
        individually: 'Individually',
        full: 'Full',
        received: 'Received',
        report: 'Report',
        regional_abbreviations: 'Regional Abbreviations',
        name_of_organisation: 'Name Of Organisation',
        form_of_documents: 'Form Of Documents',
        statistical_coverage: 'Statistical Coverage',
        requiredMsg: 'Mandatory information not filled in!',
    },
    // 德
    deDE: {
        area: 'Bereich',
        // monthly_case_count: 'Monatliche Fallanzahl',
        // monthly_closed: 'Monatlich geschlossen',
        // monthly_closure_rate: 'Monatliche Schließungsrate',
        // accumulated_closure_rate: 'Kumulierte Schließungsrate',
        // case_count_closure_rate:'Fallzahl und Abschlussrate',
        monthly_case_count: 'Monthly Case Count', // 月度案件数
        monthly_closed: 'Monthly Closed', // 月度已关闭
        monthly_closure_rate: 'Monthly Closure Rate', // 月度关闭率
        accumulated_closure_rate: 'Accumulated Closure Rate', // 累计关闭率
        case_count_closure_rate: 'Case Count and Closure Rate',
        Key_market_technical_support_performance_statistics:
            'Key market technical support performance statistics',
        Accumulated_case_count: 'Accumulated case count',
        Accumulated_closure_count: 'Accumulated closure count',
        month_case_count: 'month case ount',
        month_closure_count: 'month closure count',
        five_Day_closure_rate: 'Five day closure rate',
        five_Day_closure_count: 'Five day closure count',
        response_count_24hour: 'response count 24 hour',
        response_rate_24hour: 'response rate 24 hour',
        month_response_rate_24hour: 'response rate 24 hour',
        form_type: 'Typ des Formulars',
        repeat_multiple_choice_msg: 'Monat und Region können nicht gleichzeitig ausgewählt werden!',
        day_closure_rate: 'day closure rate',
        months_case_count: 'anzahl der Fälle pro Monat',
        individually: 'Individually',
        full: 'Full',
        received: 'Received',
        report: 'Report',
        regional_abbreviations: 'Regional Abbreviations',
        name_of_organisation: 'Name Of Organisation',
        form_of_documents: 'Form Of Documents',
        statistical_coverage: 'Statistical Coverage',
        requiredMsg: 'Mandatory information not filled in!',
    },
    // 法
    frFR: {
        area: 'Zone',
        // monthly_case_count: "Nombre mensuel d'affaires",
        // monthly_closed: 'Fermé mensuellement',
        // monthly_closure_rate: 'Taux de fermeture mensuel',
        // accumulated_closure_rate: 'Taux de fermeture cumulé',
        // case_count_closure_rate:'Nombre de cas et taux de fermeture'
        monthly_case_count: 'Monthly Case Count', // 月度案件数
        monthly_closed: 'Monthly Closed', // 月度已关闭
        monthly_closure_rate: 'Monthly Closure Rate', // 月度关闭率
        accumulated_closure_rate: 'Accumulated Closure Rate', // 累计关闭率
        case_count_closure_rate: 'Case Count and Closure Rate',
        Key_market_technical_support_performance_statistics:
            'Key market technical support performance statistics',
        Accumulated_case_count: 'Accumulated case count',
        Accumulated_closure_count: 'Accumulated closure count',
        month_case_count: 'month case ount',
        month_closure_count: 'month closure count',
        five_Day_closure_rate: 'Five day closure rate',
        five_Day_closure_count: 'Five day closure count',
        response_count_24hour: 'response count 24 hour',
        response_rate_24hour: 'response rate 24 hour',
        month_response_rate_24hour: 'response rate 24 hour',
        form_type: 'Type de formulaire',
        repeat_multiple_choice_msg:
            'Le mois et la région ne peuvent pas être sélectionnés simultanément !',
        day_closure_rate: 'day closure rate',
        months_case_count: 'nombre de cas par mois',
        individually: 'Individually',
        full: 'Full',
        received: 'Received',
        report: 'Report',
        regional_abbreviations: 'Regional Abbreviations',
        name_of_organisation: 'Name Of Organisation',
        form_of_documents: 'Form Of Documents',
        statistical_coverage: 'Statistical Coverage',
        requiredMsg: 'Mandatory information not filled in!',
    },
    // 意大利
    itIT: {
        area: 'Area',
        // monthly_case_count: 'Numero mensile di casi',
        // monthly_closed: 'Chiuso mensilmente',
        // monthly_closure_rate: 'Tasso di chiusura mensile',
        // accumulated_closure_rate: 'Tasso di chiusura cumulativo',
        // case_count_closure_rate:'Numero di casi e tasso di chiusura'
        monthly_case_count: 'Monthly Case Count', // 月度案件数
        monthly_closed: 'Monthly Closed', // 月度已关闭
        monthly_closure_rate: 'Monthly Closure Rate', // 月度关闭率
        accumulated_closure_rate: 'Accumulated Closure Rate', // 累计关闭率
        case_count_closure_rate: 'Case Count and Closure Rate',
        Key_market_technical_support_performance_statistics:
            'Key market technical support performance statistics',
        Accumulated_case_count: 'Accumulated case count',
        Accumulated_closure_count: 'Accumulated closure count',
        month_case_count: 'month case ount',
        month_closure_count: 'month closure count',
        five_Day_closure_rate: 'Five day closure rate',
        five_Day_closure_count: 'Five day closure count',
        response_count_24hour: 'response count 24 hour',
        response_rate_24hour: 'response rate 24 hour',
        month_response_rate_24hour: 'response rate 24 hour',
        form_type: 'Tipo di modulo',
        repeat_multiple_choice_msg:
            'Il mese e la regione non possono essere selezionati contemporaneamente!',
        day_closure_rate: 'day closure rate',
        months_case_count: 'numero di casi al mese',
        individually: 'Individually',
        full: 'Full',
        received: 'Received',
        report: 'Report',
        regional_abbreviations: 'Regional Abbreviations',
        name_of_organisation: 'Name Of Organisation',
        form_of_documents: 'Form Of Documents',
        statistical_coverage: 'Statistical Coverage',
        requiredMsg: 'Mandatory information not filled in!',
    },
    // 荷兰
    nlNL: {
        area: 'Gebied',
        // monthly_case_count: 'Maandelijks aantal gevallen',
        // monthly_closed: 'Maandelijks gesloten',
        // monthly_closure_rate: 'Maandelijks sluitingspercentage',
        // accumulated_closure_rate: 'Opgeslagen sluitingspercentage',
        // case_count_closure_rate:'Aantal gevallen en sluitingspercentage'
        monthly_case_count: 'Monthly Case Count', // 月度案件数
        monthly_closed: 'Monthly Closed', // 月度已关闭
        monthly_closure_rate: 'Monthly Closure Rate', // 月度关闭率
        accumulated_closure_rate: 'Accumulated Closure Rate', // 累计关闭率
        case_count_closure_rate: 'Case Count and Closure Rate',
        Key_market_technical_support_performance_statistics:
            'Key market technical support performance statistics',
        Accumulated_case_count: 'Accumulated case count',
        Accumulated_closure_count: 'Accumulated closure count',
        month_case_count: 'month case ount',
        month_closure_count: 'month closure count',
        five_Day_closure_rate: 'Five day closure rate',
        five_Day_closure_count: 'Five day closure count',
        response_count_24hour: 'response count 24 hour',
        response_rate_24hour: 'response rate 24 hour',
        month_response_rate_24hour: 'response rate 24 hour',
        form_type: 'Type formulier',
        repeat_multiple_choice_msg:
            'Maand en regio kunnen niet tegelijkertijd worden geselecteerd!',
        day_closure_rate: 'day closure rate',
        months_case_count: 'aantal gevallen per maand',
        individually: 'Individually',
        full: 'Full',
        received: 'Received',
        report: 'Report',
        regional_abbreviations: 'Regional Abbreviations',
        name_of_organisation: 'Name Of Organisation',
        form_of_documents: 'Form Of Documents',
        statistical_coverage: 'Statistical Coverage',
        requiredMsg: 'Mandatory information not filled in!',
    },
    // 越南
    viVN: {
        area: 'Khu vực',
        // monthly_case_count: 'Số vụ hàng tháng',
        // monthly_closed: 'Hàng tháng đã đóng',
        // monthly_closure_rate: 'Tỷ lệ đóng hàng tháng',
        // accumulated_closure_rate: 'Tỷ lệ đóng tích lũy',
        // case_count_closure_rate:'Số vụ và Tỷ lệ Đóng cửa'
        monthly_case_count: 'Monthly Case Count', // 月度案件数
        monthly_closed: 'Monthly Closed', // 月度已关闭
        monthly_closure_rate: 'Monthly Closure Rate', // 月度关闭率
        accumulated_closure_rate: 'Accumulated Closure Rate', // 累计关闭率
        case_count_closure_rate: 'Case Count and Closure Rate',
        Key_market_technical_support_performance_statistics:
            'Key market technical support performance statistics',
        Accumulated_case_count: 'Accumulated case count',
        Accumulated_closure_count: 'Accumulated closure count',
        month_case_count: 'month case ount',
        month_closure_count: 'month closure count',
        five_Day_closure_rate: 'Five day closure rate',
        five_Day_closure_count: 'Five day closure count',
        response_count_24hour: 'response count 24 hour',
        response_rate_24hour: 'response rate 24 hour',
        month_response_rate_24hour: 'response rate 24 hour',
        form_type: 'Loại Form',
        repeat_multiple_choice_msg: 'Tháng và khu vực không thể đồng thời chọn nhiều!',
        day_closure_rate: 'day closure rate',
        months_case_count: 'Số vụ án hàng tháng',
        individually: 'Individually',
        full: 'Full',
        received: 'Received',
        report: 'Report',
        regional_abbreviations: 'Regional Abbreviations',
        name_of_organisation: 'Name Of Organisation',
        form_of_documents: 'Form Of Documents',
        statistical_coverage: 'Statistical Coverage',
        requiredMsg: 'Mandatory information not filled in!',
    },
    // 西班牙
    esES: {
        area: 'Área',
        // monthly_case_count: 'Número de casos mensuales',
        // monthly_closed: 'Cerrados mensuales',
        // monthly_closure_rate: 'Tasa de cierre mensual',
        // accumulated_closure_rate: 'Tasa de cierre acumulada',
        // case_count_closure_rate:'Número de casos y tasa de cierre'
        monthly_case_count: 'Monthly Case Count', // 月度案件数
        monthly_closed: 'Monthly Closed', // 月度已关闭
        monthly_closure_rate: 'Monthly Closure Rate', // 月度关闭率
        accumulated_closure_rate: 'Accumulated Closure Rate', // 累计关闭率
        case_count_closure_rate: 'Case Count and Closure Rate',
        Key_market_technical_support_performance_statistics:
            'Key market technical support performance statistics',
        Accumulated_case_count: 'Accumulated case count',
        Accumulated_closure_count: 'Accumulated closure count',
        month_case_count: 'month case ount',
        month_closure_count: 'month closure count',
        five_Day_closure_rate: 'Five day closure rate',
        five_Day_closure_count: 'Five day closure count',
        response_count_24hour: 'response count 24 hour',
        response_rate_24hour: 'response rate 24 hour',
        month_response_rate_24hour: 'response rate 24 hour',
        form_type: 'Tipo de formulario',
        repeat_multiple_choice_msg: 'El mes y la región no se pueden seleccionar simultáneamente.!',
        day_closure_rate: 'day closure rate',
        months_case_count: 'número de casos por mes',
        individually: 'Individually',
        full: 'Full',
        received: 'Received',
        report: 'Report',
        regional_abbreviations: 'Regional Abbreviations',
        name_of_organisation: 'Name Of Organisation',
        form_of_documents: 'Form Of Documents',
        statistical_coverage: 'Statistical Coverage',
        requiredMsg: 'Mandatory information not filled in!',
    },
    // 葡萄牙
    ptBR: {
        area: 'Área',
        // monthly_case_count: 'Contagem mensal de casos',
        // monthly_closed: 'Fechados mensalmente',
        // monthly_closure_rate: 'Taxa de fechamento mensal',
        // accumulated_closure_rate: 'Taxa de fechamento acumulado',
        // case_count_closure_rate:'Contagem de Casos e Taxa de Encerramento'
        monthly_case_count: 'Monthly Case Count', // 月度案件数
        monthly_closed: 'Monthly Closed', // 月度已关闭
        monthly_closure_rate: 'Monthly Closure Rate', // 月度关闭率
        accumulated_closure_rate: 'Accumulated Closure Rate', // 累计关闭率
        case_count_closure_rate: 'Case Count and Closure Rate',
        Key_market_technical_support_performance_statistics:
            'Key market technical support performance statistics',
        Accumulated_case_count: 'Accumulated case count',
        Accumulated_closure_count: 'Accumulated closure count',
        month_case_count: 'month case ount',
        month_closure_count: 'month closure count',
        five_Day_closure_rate: 'Five day closure rate',
        five_Day_closure_count: 'Five day closure count',
        response_count_24hour: 'response count 24 hour',
        response_rate_24hour: 'response rate 24 hour',
        month_response_rate_24hour: 'response rate 24 hour',
        form_type: 'Tipo de formulário',
        repeat_multiple_choice_msg:
            'O mês e a região não podem ser multi-seleccionados ao mesmo tempo!',
        day_closure_rate: 'day closure rate',
        months_case_count: 'número de casos por mês',
        individually: 'Individually',
        full: 'Full',
        received: 'Received',
        report: 'Report',
        regional_abbreviations: 'Regional Abbreviations',
        name_of_organisation: 'Name Of Organisation',
        form_of_documents: 'Form Of Documents',
        statistical_coverage: 'Statistical Coverage',
        requiredMsg: 'Mandatory information not filled in!',
    },
    // 波兰
    plPL: {
        area: 'Obszar',
        // monthly_case_count: 'Liczba przypadków miesięcznie',
        // monthly_closed: 'Zamknięte miesięcznie',
        // monthly_closure_rate: 'Współczynnik zamknięcia miesięcznego',
        // accumulated_closure_rate: 'Skumulowany współczynnik zamknięcia',
        // case_count_closure_rate:'Liczba przypadków i wskaźnik zamknięcia'
        monthly_case_count: 'Monthly Case Count', // 月度案件数
        monthly_closed: 'Monthly Closed', // 月度已关闭
        monthly_closure_rate: 'Monthly Closure Rate', // 月度关闭率
        accumulated_closure_rate: 'Accumulated Closure Rate', // 累计关闭率
        case_count_closure_rate: 'Case Count and Closure Rate',
        Key_market_technical_support_performance_statistics:
            'Key market technical support performance statistics',
        Accumulated_case_count: 'Accumulated case count',
        Accumulated_closure_count: 'Accumulated closure count',
        month_case_count: 'month case ount',
        month_closure_count: 'month closure count',
        five_Day_closure_rate: 'Five day closure rate',
        five_Day_closure_count: 'Five day closure count',
        response_count_24hour: 'response count 24 hour',
        response_rate_24hour: 'response rate 24 hour',
        month_response_rate_24hour: 'response rate 24 hour',
        form_type: 'Typ formularza',
        repeat_multiple_choice_msg: 'Miesiąc i region nie mogą być wybrane jednocześnie!',
        day_closure_rate: 'day closure rate',
        months_case_count: 'liczba przypadków na miesiąc',
        individually: 'Individually',
        full: 'Full',
        received: 'Received',
        report: 'Report',
        regional_abbreviations: 'Regional Abbreviations',
        name_of_organisation: 'Name Of Organisation',
        form_of_documents: 'Form Of Documents',
        statistical_coverage: 'Statistical Coverage',
        requiredMsg: 'Mandatory information not filled in!',
    },
    // 土耳其
    trTR: {
        area: 'Alan',
        // monthly_case_count: 'Aylık Vaka Sayısı',
        // monthly_closed: 'Aylık Kapatılan',
        // monthly_closure_rate: 'Aylık Kapanma Oranı',
        // accumulated_closure_rate: 'Birikmiş Kapanma Oranı',
        // case_count_closure_rate:'Vaka Sayısı ve Kapanış Oranı'
        monthly_case_count: 'Monthly Case Count', // 月度案件数
        monthly_closed: 'Monthly Closed', // 月度已关闭
        monthly_closure_rate: 'Monthly Closure Rate', // 月度关闭率
        accumulated_closure_rate: 'Accumulated Closure Rate', // 累计关闭率
        case_count_closure_rate: 'Case Count and Closure Rate',
        Key_market_technical_support_performance_statistics:
            'Key market technical support performance statistics',
        Accumulated_case_count: 'Accumulated case count',
        Accumulated_closure_count: 'Accumulated closure count',
        month_case_count: 'month case ount',
        month_closure_count: 'month closure count',
        five_Day_closure_rate: 'Five day closure rate',
        five_Day_closure_count: 'Five day closure count',
        response_count_24hour: 'response count 24 hour',
        response_rate_24hour: 'response rate 24 hour',
        month_response_rate_24hour: 'response rate 24 hour',
        form_type: 'Form türü',
        repeat_multiple_choice_msg: 'Ay ve bölge aynı anda birden fazla seçilemez!',
        day_closure_rate: 'day closure rate',
        months_case_count: 'aylık vaka sayısı',
        individually: 'Individually',
        full: 'Full',
        received: 'Received',
        report: 'Report',
        regional_abbreviations: 'Regional Abbreviations',
        name_of_organisation: 'Name Of Organisation',
        form_of_documents: 'Form Of Documents',
        statistical_coverage: 'Statistical Coverage',
        requiredMsg: 'Mandatory information not filled in!',
    },
    // 捷克语
    csCZ: {
        area: 'Oblast',
        monthly_case_count: 'Měsíční počet případů',
        monthly_closed: 'Měsíčně uzavřeno',
        monthly_closure_rate: 'Měsíční míra uzavření',
        accumulated_closure_rate: 'Akumulovaná míra uzavření',
        case_count_closure_rate: 'Počet případů a míra uzavření',
        Key_market_technical_support_performance_statistics:
            'Key market technical support performance statistics',
        Accumulated_case_count: 'Akumulovaný počet případů',
        Accumulated_closure_count: 'Akumulovaný počet uzavření',
        month_case_count: 'měsíční počet případů',
        month_closure_count: 'počet uzavřených případů za měsíc',
        five_Day_closure_rate: 'Míra uzavření za 5 dní',
        response_count_24hour: 'počet odpovědí 24 hodin',
        five_Day_closure_count: 'počet uzavřených případů za 5 dní',
        response_rate_24hour: 'rychlost odezvy 24 hodin',
        month_response_rate_24hour: 'měsíční rychlost odezvy 24 hodin',
        form_type: 'Typ formuláře',
        repeat_multiple_choice_msg: 'Měsíc a oblast nelze vybrat současně vícekrát!',
        day_closure_rate: 'Míra uzavření za dní',
        months_case_count: 'počet případů za měsíc',
        individually: 'Individually',
        full: 'Full',
        received: 'Received',
        report: 'Report',
        regional_abbreviations: 'Regional Abbreviations',
        name_of_organisation: 'Name Of Organisation',
        form_of_documents: 'Form Of Documents',
        statistical_coverage: 'Statistical Coverage',
        requiredMsg: 'Mandatory information not filled in!',
    },
    // 匈牙利语
    huHU: {
        area: 'Terület',
        monthly_case_count: 'Havi esetszám',
        monthly_closed: 'Havi lezárt',
        monthly_closure_rate: 'Havi lezárási arány',
        accumulated_closure_rate: 'Összesített lezárási arány',
        case_count_closure_rate: 'Esetszám és lezárási arány',
        Key_market_technical_support_performance_statistics:
            'Fő piac műszaki támogatásának teljesítménystatisztikái',
        Accumulated_case_count: 'Összesített esetszám',
        Accumulated_closure_count: 'Összesített lezárási szám',
        month_case_count: 'Havi esetszám',
        month_closure_count: 'Havi lezárási szám',
        five_Day_closure_rate: 'Ötnapos lezárási arány',
        five_Day_closure_count: 'Ötnapos lezárási szám',
        response_count_24hour: '24 órás reakciók száma',
        response_rate_24hour: '24 órás reakciók aránya',
        month_response_rate_24hour: 'Havi 24 órás reakciók aránya',
        form_type: 'Űrlap típusa',
        repeat_multiple_choice_msg: 'A hónap és a terület nem választható egyszerre!',
        day_closure_rate: 'napi zárási arány',
        months_case_count: 'az esetek száma havonta',
        individually: 'Individually',
        full: 'Full',
        received: 'Received',
        report: 'Report',
        regional_abbreviations: 'Regional Abbreviations',
        name_of_organisation: 'Name Of Organisation',
        form_of_documents: 'Form Of Documents',
        statistical_coverage: 'Statistical Coverage',
        requiredMsg: 'Mandatory information not filled in!',
    },
    // 俄罗斯语
    ruRU: {
        area: 'Область',
        monthly_case_count: 'Ежемесячное количество дел',
        monthly_closed: 'Ежемесячно закрыто',
        monthly_closure_rate: 'Ежемесячный коэффициент закрытия',
        accumulated_closure_rate: 'Накопленный коэффициент закрытия',
        case_count_closure_rate: 'Коэффициент закрытия по количеству дел',
        Key_market_technical_support_performance_statistics:
            'Статистика производительности технической поддержки ключевых рынков',
        Accumulated_case_count: 'Накопленное количество дел',
        Accumulated_closure_count: 'Накопленное количество закрытий',
        month_case_count: 'Месячное количество дел',
        month_closure_count: 'Месячное количество закрытий',
        five_Day_closure_rate: 'Коэффициент закрытия за 5 дней',
        five_Day_closure_count: 'Количество закрытий за 5 дней',
        response_count_24hour: 'Количество ответов за 24 часа',
        response_rate_24hour: 'Коэффициент ответов за 24 часа',
        month_response_rate_24hour: 'Коэффициент ответов за 24 часа',
        form_type: 'Тип формы',
        repeat_multiple_choice_msg: 'Месяц и область не могут быть выбраны одновременно!',
        day_closure_rate: 'Коэффициент закрытия за день',
        months_case_count: 'Ежемесячное количество дел',
        individually: 'Individually',
        full: 'Full',
        received: 'Received',
        report: 'Report',
        regional_abbreviations: 'Regional Abbreviations',
        name_of_organisation: 'Name Of Organisation',
        form_of_documents: 'Form Of Documents',
        statistical_coverage: 'Statistical Coverage',
        requiredMsg: 'Mandatory information not filled in!',
    },
};
