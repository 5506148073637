import type { MaybeRefOrGetter } from '@vueuse/core';
import { type FormItemGiProps, NText, NTooltip } from 'naive-ui';
import type { GetObjectOutput } from '@aws-sdk/client-s3';
import * as binconv from 'binconv';
import type { AxiosHeaders, RawAxiosRequestHeaders } from 'axios';
import { globalMessage } from './discreteApi';
import { i18nGlobal } from '~/utils/i18n';

export const getFileSize = (
    file?: File | null | number,
    unit: 'MB' | 'GB' | 'KB' = 'MB',
    fixed?: number,
) => {
    if (!file) return 0;
    let res = 0;
    const size = typeof file === 'number' ? file : file.size;
    if (unit === 'KB') res = ((size / 1024) * 100) / 100;
    if (unit === 'GB') res = ((size / 1024 / 1024 / 1024) * 100) / 100;
    if (unit === 'MB') res = ((size / 1024 / 1024) * 100) / 100;
    if (fixed) {
        const pow = 10 ** fixed;
        res = Math.round(res * pow) / pow;
    }
    return res;
};

// 根据文件名 是图片返回 true
export const canFileBePreviewedByName = (fileName: string) => {
    const regex = /[\s\S](\.gif|\.jpeg|\.png|\.jpg|\.bmp|\.webp)/i;
    return !(fileName.search(regex) === -1);
};

export function paramsFilter(params: { [key: string]: any }): Record<string, any> {
    const reqParam: { [key: string]: any } = {};
    for (const key in params) {
        if (params[key] || params[key] === 0) {
            reqParam[key] = params[key];
        }
    }
    return reqParam;
}

export const createDownload = (blobOrUrl: Blob | string, filename?: string) => {
    const elink = document.createElement('a');

    if ('download' in elink) {
        elink.download = filename || 'file';
        elink.style.display = 'none';

        elink.href = typeof blobOrUrl === 'string' ? blobOrUrl : URL.createObjectURL(blobOrUrl);

        document.body.appendChild(elink);
        elink.click();
        document.body.removeChild(elink);
    }
};

export function getFileNameFormHeader(headers: AxiosHeaders) {
    const header = (headers as RawAxiosRequestHeaders)['content-disposition'] as string;
    return decodeURIComponent((header || '').split('filename=')[1]).replaceAll('"', '');
}

export function downloadCallback(response: Blob | any, filename?: string) {
    const isBlob = response instanceof Blob;

    let blob: Blob;
    if (isBlob) {
        blob = response as Blob;
        if (blob.size === 0) return globalMessage.warning(i18nGlobal.t('export.noDataToExport'));
    } else {
        const res = response as Blob;
        if (!res) return globalMessage.warning(i18nGlobal.t('export.noDataToExport'));
        blob = res;
        if (res.size === 0) return globalMessage.warning(i18nGlobal.t('export.noDataToExport'));
    }
    createDownload(blob, filename);
}

export const getRecordIndex = (
    params: MaybeRefOrGetter<{ index: number; size: number; page: number }>,
) => {
    const { index, size, page } = resolveUnref(params);
    return (page - 1) * size + index + 1;
};

export type FilterNullConifg = {
    filterEmptyString?: boolean;
    deepClone?: boolean;
};
export type UnknownAnyKeyRecord = Record<string | number | symbol, unknown>;
export type UnknownCommonKeyRecord = Record<string | number, unknown>;

export const filterNull = <T extends UnknownAnyKeyRecord>(
    records: T,
    config: FilterNullConifg = {
        filterEmptyString: false,
        deepClone: false,
    },
) => {
    let copy: {} & T;
    if (Array.isArray(records)) copy = Object.assign([], records);
    else copy = Object.assign({}, records);
    const { filterEmptyString, deepClone } = config;
    let newObj: {} & T = copy;

    if (deepClone) newObj = structuredClone ? structuredClone(copy) : clone(copy, true);
    const keys = Reflect.ownKeys(newObj);
    for (const key of keys) {
        const val = Reflect.get(records, key);
        if (!val && val !== 0) {
            if (typeof val !== 'string') {
                Reflect.deleteProperty(records, key);
                continue;
            } else if (filterEmptyString && val === '') Reflect.deleteProperty(records, key);
            else if (!filterEmptyString && val === '') continue;
        }
    }

    return newObj as { [P in keyof T]: NonNullable<T[P]> };
};

export type SetLabelWidthByColumnRule = {
    labelWidth: number;
};

export const setLabelWidthByColumn = (
    list: { formItemGiProps: FormItemGiProps }[],
    rule: SetLabelWidthByColumnRule[],
) => {
    let column = 1;
    for (let i = 0; i <= list.length; i++) {
        const item = list[i];
        if (!item.formItemGiProps) item.formItemGiProps = {};
        item.formItemGiProps.labelWidth = rule[column - 1].labelWidth;
        column += 1;
        if (column > rule.length) column = 1;
    }
};
export type ls = {
    labelWidth: number;
    status: boolean;
};
export const useCont = (list, column: number, statusYi: ls, statusEr: ls, statusSan: ls) => {
    list.map((item, index) => {
        if (column % 2 === 0) {
            if ((index + 1) % 2 === 1 && statusYi.status) {
                item.formItemGiProps.labelWidth = statusYi.labelWidth;
            } else if ((index + 1) % 2 === 0 && statusEr.status) {
                item.formItemGiProps.labelWidth = statusEr.labelWidth;
            }
        } else if (column % 2 === 1) {
            if ((index + 1) % 3 === 1 && statusYi.status) {
                item.formItemGiProps.labelWidth = statusYi.labelWidth;
            } else if ((index + 1) % 3 === 2 && statusEr.status) {
                item.formItemGiProps.labelWidth = statusEr.labelWidth;
            } else if ((index + 1) % 3 === 0 && statusSan.status) {
                item.formItemGiProps.labelWidth = statusSan.labelWidth;
            }
        }
        return list;
    });
};
export const useContForm = (list, statusYi: ls, statusEr: ls, statusSan: ls, statusSi: ls) => {
    list.map((item, index) => {
        if ((index + 1) % 4 === 1 && statusYi.status) {
            item.formItemGiProps.labelWidth = statusYi.labelWidth;
        } else if ((index + 1) % 4 === 2 && statusEr.status) {
            item.formItemGiProps.labelWidth = statusEr.labelWidth;
        } else if ((index + 1) % 4 === 3 && statusSan.status) {
            item.formItemGiProps.labelWidth = statusSan.labelWidth;
        } else if ((index + 1) % 4 === 0 && statusSi.status) {
            item.formItemGiProps.labelWidth = statusSi.labelWidth;
        }
        return list;
    });
};

export const blobToImage = async (data: GetObjectOutput) => {
    const blob = await binconv.readableStreamToBlob(data.Body as ReadableStream);
    const newBlob = new Blob([blob], { type: data.ContentType });
    const url = URL.createObjectURL(newBlob);
    return url;
};

export const chinese = /^[\u4E00-\u9FA5]$/;

export const getByteLength = (str: string) => {
    if (str.length === 0) {
        return 0;
    }
    const charList = str.split('');
    let l = 0;
    charList.forEach((el) => {
        chinese.test(el) ? (l += 3) : (l += 1);
    });
    return l;
};

// table action宽度设置
export const getActionWidth = (locale, buttonList) => {
    let newTargetWidth = 100;
    if (buttonList && buttonList.length > 0) {
        let buttonText = '';
        buttonList.forEach((i) => {
            buttonText = buttonText + i;
        });
        if (locale === 'zh-CN') {
            newTargetWidth = buttonText.length * 20;
        } else {
            newTargetWidth = buttonText.length * 10;
            if (newTargetWidth > 280) {
                newTargetWidth = 280;
            }
        }
    }

    return newTargetWidth;
};

export const getMonth = (month) => {
    if (month === 1) {
        return i18nGlobal.t('ymd.january');
    } else if (month === 2) {
        return i18nGlobal.t('ymd.february');
    } else if (month === 3) {
        return i18nGlobal.t('ymd.march');
    } else if (month === 4) {
        return i18nGlobal.t('ymd.april');
    } else if (month === 5) {
        return i18nGlobal.t('ymd.may');
    } else if (month === 6) {
        return i18nGlobal.t('ymd.june');
    } else if (month === 7) {
        return i18nGlobal.t('ymd.july');
    } else if (month === 8) {
        return i18nGlobal.t('ymd.august');
    } else if (month === 9) {
        return i18nGlobal.t('ymd.september');
    } else if (month === 10) {
        return i18nGlobal.t('ymd.october');
    } else if (month === 11) {
        return i18nGlobal.t('ymd.november');
    } else if (month === 12) {
        return i18nGlobal.t('ymd.december');
    }
};

// 根据角色代码获取公告通知地址
export const tuUrlPath = (a?: any | null, b?: any | null) => {
    switch (a) {
    case 'snw-oversea-subcompany':
        switch (b) {
        case 'MG':
            return '/info/announcement/announcementManageOc';
        case 'ZJ':
            return '/info/announcement/imAnnouncementManageOc';
        default:
            return '';
        }

    case 'snw-oversea-company':
        switch (b) {
        case 'MG':
            return '/info/announcement/announcementManageOc';
        case 'ZJ':
            return '/info/announcement/imAnnouncementManageOc';
        default:
            return '';
        }
    case 'snw-smil':
        switch (b) {
        case 'MG':
            return '/info/announcement/announcementManageHq';
        case 'ZJ':
            return '/info/announcement/imAnnouncementManageHq';
        default:
            return '';
        }

    case 'snw-top-agent':
        switch (b) {
        case 'MG':
            return '/info/announcement/announcementManageOg';
        case 'ZJ':
            return '/info/announcement/imAnnouncementManageOg';
        default:
            return '';
        }

    case 'snw-top-dealer':
        switch (b) {
        case 'MG':
            return '/info/announcement/announcementManageOd';
        case 'ZJ':
            return '/info/announcement/imAnnouncementManageOd';
        default:
            return '';
        }

    case 'snw-tech-doc':
        return '';
    default:
        return '';
    }
};
export const tuUrlInfoPath = (a?: any | null, b?: any | null) => {
    switch (a) {
    case 'snw-oversea-subcompany':
    case 'snw-oversea-company':
    case 'snw-top-agent':
        switch (b) {
        case 'MG':
            return '/info/announcementInfo/manageOcdg';
        case 'ZJ':
            return '/info/announcementInfo/imManageOcdg';
        default:
            return '';
        }
    case 'snw-smil':
        switch (b) {
        case 'MG':
            return '/info/announcementInfo/manageHq';
        case 'ZJ':
            return '/info/announcementInfo/imManageHq';
        default:
            return '';
        }
    case 'snw-top-dealer':
        switch (b) {
        case 'MG':
            return '/info/announcementInfo/manageOd';
        case 'ZJ':
            return '/info/announcementInfo/imManageOd';
        default:
            return '';
        }
    case 'snw-tech-doc':
        return '';
    default:
        return '';
    }
};

// 获取文件后缀名
export const GetExtensionFileName = (pathfilename: any) => {
    const reg = /(\\+)/g;
    const pfn = pathfilename.replace(reg, '#');
    const arrpfn = pfn.split('#');
    const fn = arrpfn[arrpfn.length - 1];
    const arrfn = fn.split('.');
    return arrfn[arrfn.length - 1];
};

// 判断文件后缀名是否在可选内
export const FileNameIsSelect = (pathfilename: any) => {
    const reg = /(\\+)/g;
    const pfn = pathfilename.replace(reg, '#');
    const arrpfn = pfn.split('#');
    const fn = arrpfn[arrpfn.length - 1];
    const arrfn = fn.split('.');
    const haveFn = ref(false);
    ['xls', 'xlsx', 'docx', 'pdf', 'mp4', 'gif', 'jpeg', 'png', 'jpg', 'bmp', 'webp'].forEach(
        (i: any) => {
            if (i === arrfn[arrfn.length - 1]) {
                haveFn.value = true;
            }
        },
    );
    return haveFn.value;
};

export const FileNamePDF = (pathfilename: any) => {
    const reg = /(\\+)/g;
    const pfn = pathfilename.replace(reg, '#');
    const arrpfn = pfn.split('#');
    const fn = arrpfn[arrpfn.length - 1];
    const arrfn = fn.split('.');
    const haveFn = ref(false);
    ['pdf'].forEach((i: any) => {
        if (i === arrfn[arrfn.length - 1]) {
            haveFn.value = true;
        }
    });
    return haveFn.value;
};

// 下拉框选项过长是悬浮框
export const renderSelectLabel = (option: any) => {
    return h(
        NTooltip,
        { placement: 'top' },
        {
            trigger: () => h('div', { class: 'option-label' }, option.label),
            default: () => h(NText, { style: 'color: #fff' }, option.label),
        },
    );
};
