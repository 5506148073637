import type { DataTableColumn } from 'naive-ui';
import { NButton, useDialog } from 'naive-ui';
import { useRequests } from './request';
import { getActionWidth, getRecordIndex } from '~/utils/toolFunction';

export const useTableData = createGlobalState(() => {
    const tableData = ref<any[]>();
    const totalPages = ref<number>(0);
    const total = ref(0);

    return {
        tableData,
        totalPages,
        total,
    };
});

export const useTable = (params) => {
    const { t, locale } = useTypedI18n();
    const { dayJs } = useDayJs();

    const { caseExportTaskList } = useRequests();
    const { downloadObject, deleteObject } = useAwsS3();

    const { tableData, loading, refreshTableData, totalPages, total } = caseExportTaskList(params);

    const columns = computed(() =>
        generateTableColumns([
            {
                title: t('page.system.user.fileName'),
                key: 'fileName',
                width: 120,
            },
            {
                title: t('page.system.user.creation_time'),
                key: 'createTime',
                width: 120,
                render: (e: any) => {
                    return dayJs.value(e.createTime * 1000).format('YYYY-MM-DD HH:mm:ss');
                },
            },
            {
                title: t('page.system.user.taskStatus'),
                key: 'taskStatus',
                width: 120,
            },
            {
                title: t('table.action'),
                key: 'actions',
                width: 100,
                fixed: 'right',
                render(row) {
                    return h('div', [
                        h(
                            NButton,
                            {
                                style: {
                                    marginRight: '10px',
                                },
                                type: 'primary',
                                size: 'small',
                                text: true,
                                disabled: row.taskStatus !== 'Complete',
                                onClick: toDown.bind(null, row),
                            },
                            {
                                default: () => t('button.download'),
                            },
                        ),
                    ]);
                },
            },
        ]),
    );

    const toDown = async (row: any) => {
        const lastIndex = row.fileName.lastIndexOf('.');
        const beforeStr = row.fileName.slice(0, lastIndex);
        const res = await downloadObject(row.filePath, beforeStr);
        if (res) {
            // console.log(res);
        }
    };

    return {
        tableData,
        columns,
        loading,
        refreshTableData,
        params,
        totalPages,
        total,
    };
};
