import type { MessageFunction } from 'vue-i18n';
import homepage from './pages/homepage/index';
import tech from './pages/tech/index';
import statistics from './pages/statistics/index';
import Info from './pages/info/index';
import system from './pages/system/index';
// 荷兰
export default {
    license: {
        powered_by: 'Aangedreven door SMIL',
    },
    auth: {
        login: 'Inloggen',
        username: 'Gebruikersnaam',
        username_placeholder: 'Voer gebruikersnaam in',
        password: 'Wachtwoord',
        password_placeholder: 'Voer wachtwoord in',
        code: 'Verificatiecode',
        code_placeholder: 'Voer verificatiecode in',
        logout: 'Uitloggen',
        logout_success: 'Uitloggen geslaagd',
        day: 'Dag',
        password_expiration: 'Het wachtwoord is verlopen, pas het aan',
        expire: 'Blijven tot wachtwoordverval',
        conseils:
            'Het wachtwoord moet hoofdletters, kleine letters, cijfers en speciale tekens ({msg}) bevatten en de lengte van het wachtwoord moet tussen 8 tot 16 tekens liggen',
        brand_Selection: 'Merkselectie',
        unread_notices: 'Ongelezen meldingen',
        new_cases: 'Nieuwe gevallen',
        unanswered_cases: 'Onbeantwoorde gevallen',
        overdue_cases: 'Achterstallige gevallen',
    },
    button: {
        query: 'Query',
        editing_position: 'Bewerkingspositie',
        show_theme_editor: 'Toon/Verberg Thema Editor',
        change_password: 'Wijzig Wachtwoord',
        old_password: 'Oud wachtwoord',
        new_password: 'Nieuw wachtwoord',
        confirm_password: 'Wachtwoord bevestigen',
        button: 'Knop',
        about: 'Over',
        back: 'Terug',
        go: 'GA',
        home: 'Home',
        confirm: 'Bevestigen',
        modify: 'Wijzigen',
        edit: 'Bewerk',
        new: 'Nieuw',
        cancel: 'Annuleren',
        close: 'Sluiten',
        search: 'Zoeken',
        reset: 'Reset',
        delete: 'Verwijderen',
        add: 'Toevoegen',
        collapse: 'Inklappen',
        expand: 'Meer',
        download_template: 'Sjabloon downloaden',
        batch_import: 'Batch importeren',
        import: 'Importeren',
        export: 'Exporteren',
        export_fail: 'Export Mislukt',
        detailed: 'Detail',
        details: 'Details',
        download: 'Downloaden',
        toView: 'Controleren',
        toViews: 'afbeelding bekijken',
        uploadAttachments: 'Bijlagen uploaden',
        selectFile: 'Bestand selecteren',
        fillingInstructions: 'Invulinstructies',
        release: 'Vrijgeven',
        release_recall: 'Release terugroepen',
        save: 'Opslaan',
        send: 'Verzenden',
        tips: 'Tips',
        send_back: 'Retourneren',
        examine: 'Onderzoeken',
        void: 'Ongeldig maken',
        more: 'Meer',
        refresh: 'Vernieuwen',
        selects: 'Selecteer',
        serial_number: 'Nr.',
        upload_progress: 'Uploadvoortgang',
        invalid: 'Ongeldig',
        daily_record: 'Dagelijks record',
        operation_type: 'Type bewerking',
        field_name: 'Veldnaam',
        edit_role: 'Rol bewerken',
        user_role: 'Gebruikersrol',
        assignment_role: 'Rol toewijzen',
        post: 'Post',
        sending: 'Bezig met verzenden',
        reset_password: 'Wachtwoord resetten',
        display_or_not: 'Weergave of niet',
        document: 'Document',
        specificTemplates: 'Specifieke sjablonen',
        transferOrder: 'Overdrachtsopdracht',
        forwarding: 'Doorsturen',
        one_click_notification: 'Eén klik melding',
        english_export: 'English Export',
        oneclick_download: 'Download met één klik',
    },
    radio: {
        enabled: 'Ingeschakeld',
        disabled: 'Uitgeschakeld',
        catalogue: 'Catalogus',
        individual: 'Individueel',
        all: 'Allemaal',
        yes: 'Ja',
        no: 'Nee',
    },
    dropdown: {
        close_others: 'Anderen sluiten',
        close_right: 'Rechts sluiten',
        close_left: 'Links sluiten',
    },
    table: {
        action: 'Operatie',
        explain: 'Uitleg',
        fileName: 'Naam accessoire',
        size: 'Grootte(MB)',
        uploader: 'Uploader',
        uploadTime: 'Upload Tijd',
        system: 'Systeem',
        forwarding_time: 'Verzendtijd',
        forwarder: 'Forwarder',
        send_to: 'Verzenden naar',
        mode: 'Mode',
    },
    pagination: {
        pages: ({ named }: Parameters<MessageFunction>[0]) => `${named('number')}/pagina`,
        total: ({ named }: Parameters<MessageFunction>[0]) => `${named('number')} artikel gegevens`,
        vinCount: ({ named }: Parameters<MessageFunction>[0]) =>
            `${named('number')} voertuigen in totaal`,
    },
    confirm: {
        confirm_deletion: 'Bevestig verwijdering?',
        confirm_void: 'Bevestig annulering?',
        confirm_release: 'Bevestig vrijgave?',
        ack: 'Bevestigen',
        cancel: 'Annuleren',
        non_records: 'Selecteer ten minste één record',
    },
    request: {
        failed_placeholder: 'Verzoek mislukt, backend-fout',
        success_placeholder: 'Verzoek succesvol',
        auth: {
            login_success: 'Inloggen succesvol',
        },
        error: {
            '404': 'Verzoek-URL niet gevonden',
            '403': 'Toestemming geweigerd',
        },
    },
    page: {
        global: {
            page_search: 'Pagina zoeken',
        },
        error: {
            not_found: 'Niet gevonden',
            not_found_des: 'Er is hier niets',
            auth_failed: 'Authenticatie mislukt',
            auth_failed_des: 'Deze pagina is niet bereikbaar',
            file_format_error: 'Bestandsformaat abnormaal!',
            preview_failed: 'Voorbeeld mislukt! Bestand abnormaal!',
        },
        homepage: {
            homepage: homepage.homepage.nlNL,
        },
        system: {
            menu: system.menu.nlNL,
            dict: system.dict.nlNL,
            dept: system.dept.nlNL,
            role: system.role.nlNL,
            user: system.user.nlNL,
        },
        info: {
            announcement: Info.announcement.announcement.nlNL,
            document: Info.document.document.nlNL,
        },
        tech: {
            quality: {
                problemTracking: tech.quality.problemTracking.nlNL,
                recallActivity: tech.quality.recallActivity.nlNL,
                abnormalClaimTracking: tech.quality.abnormalClaimTracking.nlNL,
            },
            management: {
                inquiryOd: tech.management.inquiry.nlNL,
                permission: tech.management.permission.nlNL,
                onSiteSupportHq: tech.management.onSiteSupport.nlNL,
                progHistoryHq: tech.management.progHistory.nlNL,
                salesRecordHq: tech.management.salesRecord.nlNL,
                vehicleInfoHq: tech.management.vehicleInfo.nlNL,
                claimHq: tech.management.claim.nlNL,
                filterHq: tech.management.filter.nlNL,
                newCarlssue: tech.management.newCarIssue.nlNL,
                newCarDefinition: tech.management.newCarDefinition.nlNL,
                vehicleProductionInfo: tech.management.vehicleProductionInfo.nlNL,
                programmingHistoryInformationQuery: tech.management.programmingHistoryInformationQuery.nlNL,
                ecuControllerInformationQuery: tech.management.ecuControllerInformationQuery.nlNL,
            },
        },
        components: {
            businessComponents: tech.management.businessComponents.nlNL,
        },
        statistics: statistics.inquiryStatistics.nlNL,
        systemClassifyA: statistics.systemClassifyA.nlNL,
        systemClassifyB: statistics.systemClassifyB.nlNL,
        engineerStatistics: statistics.engineerStatistics.nlNL,
        defectivePart: statistics.defectivePart.nlNL,
        replyInquiryStatistics: statistics.replyInquiryStatistics.nlNL,
        passRateStatistics: statistics.passRateStatistics.nlNL,
        timelyResponseRateStatistics: statistics.timelyResponseRateStatistics.nlNL,
        responseRateStatistics24Hour: statistics.responseRateStatistics24Hour.nlNL,
        customsClearance135Rate: statistics.customsClearance135Rate.nlNL,
        accumulatedClosureRate: statistics.accumulatedClosureRate.nlNL,
        engineerAffiliatedOrganization: statistics.engineerAffiliatedOrganization.nlNL,
        EngineerResponseRateReport: statistics.EngineerResponseRateReport.nlNL,
    },
    validation: {
        common: {
            required: 'Item is verplicht',
            numeric: 'Moet een geheel getal zijn',
            decimal: 'Moet een getal zijn',
            positive_interger: 'Moet een positief geheel getal zijn',
            up_to_digits: ({ named }: Parameters<MessageFunction>[0]) =>
                `Tot aan ${named('number')} cijfers`,
            more_than_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `Meer dan ${named('number')} karakters`,
            max_bytes: ({ named }: Parameters<MessageFunction>[0]) =>
                `Maximale bytes overschrijden (${named('number')})`,
            less_than_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `Minder dan ${named('number')} karakters`,
            must_be_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `Moet wel zijn. ${named('number')} karakters`,
            greater_than: ({ named }: Parameters<MessageFunction>[0]) =>
                `Groter dan ${named('number')}`,
            less_than: ({ named }: Parameters<MessageFunction>[0]) =>
                `Minder dan ${named('number')}`,
            up_to_2_decimal_places: 'Tot 2 decimalen',
            retain_up: 'Voer tot 10 positieve gehele getallen in en behoud tot 4 decimalen',
            retain_ups: 'Voer tot 11 positieve gehele getallen in en behoud tot 4 decimalen',
            input_number: 'Voer het nummer in',
            only_number_a: 'Alleen letters of cijfers kunnen worden ingevoerd',
            translate: 'Kan alleen letters zijn',
            positive_integer: 'Voer tot 10 positieve gehele getallen in',
            at_least_one: 'Vul minstens één in',
        },
        tip: {
            incomplete_information: 'Onvolledige informatie',
        },
    },
    import: {
        template_file: 'Sjabloonbestand',
        browse: 'Bladeren',
        download_template: 'Sjabloon downloaden',
        no_file_selected: 'Geen bestand geselecteerd',
        incorrect_file_type: 'Fout bestandstype',
        exceed: 'Verder gaan',
        upload_success: 'Upload succesvol',
        upload_fail: 'Upload mislukt',
        again_upload: 'Alleen afbeeldingen in jpg, png, bmp of gif formaten kunnen worden geüpload',
        attachmentUpload: 'Bijlage uploaden',
        supportedFormats: 'Ondersteunde bestandsformaten',
        preview: 'Voorbeeld',
    },
    export: {
        noDataToExport: 'Geen gegevens om te exporteren',
    },
    select: {
        all: 'Alle',
    },
    checkbox: {
        select_all: 'Alles selecteren',
    },
    dialog: {
        error_message: 'Foutmelding',
    },
    ymd: {
        day: 'dag',
        month: 'maand',
        year: 'jaar',
        january: 'Jan',
        february: 'Feb',
        march: 'Mar',
        april: 'Apr',
        may: 'May',
        june: 'Jun',
        july: 'Jul',
        august: 'Aug',
        september: 'Sep',
        october: 'Oct',
        november: 'Nov',
        december: 'Dec',
    },
};
