import type { MessageFunction } from 'vue-i18n';
import homepage from './pages/homepage/index';
import tech from './pages/tech/index';
import statistics from './pages/statistics/index';
import Info from './pages/info/index';
import system from './pages/system/index';
// 波兰
export default {
    license: {
        powered_by: 'Powered by SMIL',
    },
    auth: {
        login: 'Zaloguj się',
        username: 'Nazwa użytkownika',
        username_placeholder: 'Wprowadź nazwę użytkownika',
        password: 'Hasło',
        password_placeholder: 'Wprowadź hasło',
        code: 'Kod',
        code_placeholder: 'Wprowadź kod',
        logout: 'Wyloguj',
        logout_success: 'Wylogowano pomyślnie',
        day: 'Dzień',
        password_expiration: 'Hasło wygasło, proszę zmienić',
        expire: 'Do wygaśnięcia hasła pozostało',
        conseils: 'Hasło musi zawierać wielką literę, małą literę, cyfrę, znak specjalny ({msg}); długość hasła od 8 do 16 znaków',
        brand_Selection: 'Wybór marki',
        unread_notices: 'Nieprzeczytane powiadomienia',
        new_cases: 'Nowe przypadki',
        unanswered_cases: 'Niezatwarte przypadki',
        overdue_cases: 'Przeterminowane przypadki',
    },
    button: {
        query: 'Zapytanie',
        editing_position: 'Edytowanie stanowiska',
        show_theme_editor: 'Pokaż/ukryj edytor motywu',
        change_password: 'Zmień hasło',
        old_password: 'Stare hasło',
        new_password: 'Nowe hasło',
        confirm_password: 'Potwierdź hasło',
        button: 'Przycisk',
        about: 'O nas',
        back: 'Powrót',
        go: 'OK',
        home: 'Strona główna',
        confirm: 'Potwierdź',
        modify: 'Modyfikuj',
        edit: 'Edytuj',
        new: 'Dodaj nowy',
        cancel: 'Anuluj',
        close: 'Zamknij',
        search: 'Szukaj',
        reset: 'Resetuj',
        delete: 'Usuń',
        add: 'Dodaj',
        collapse: 'Zwiń',
        expand: 'Rozwiń',
        download_template: 'Pobierz szablon',
        batch_import: 'Importuj partiami',
        import: 'Importuj',
        export: 'Eksportuj',
        export_fail: 'Eksport nieudany',
        detailed: 'Szczegółowy',
        details: 'Szczegóły',
        download: 'Pobierz',
        toView: 'Wyświetl',
        toViews: 'Wyświetl obraz',
        uploadAttachments: 'Prześlij załączniki',
        selectFile: 'Wybierz plik',
        fillingInstructions: 'Instrukcje wypełniania',
        release: 'Publikuj',
        release_recall: 'Wycofanie wydania',
        save: 'Zapisz',
        send: 'Wyślij',
        tips: 'Wskazówki',
        send_back: 'Wyślij z powrotem',
        examine: 'Przejrzyj',
        void: 'Anuluj',
        more: 'Więcej',
        refresh: 'Odśwież',
        selects: 'Wybierz',
        serial_number: 'Numer seryjny',
        upload_progress: 'Postęp przesyłania',
        invalid: 'Nieprawidłowy',
        confirm_number: 'Potwierdź numer kontenera:',
        empty_data: 'Wyczyść dane generowania',
        variance_details: 'Szczegóły różnic',
        daily_record: 'Dziennik',
        operation_type: 'Rodzaj operacji',
        field_name: 'Nazwa pola',
        edit_role: 'Edytuj rolę',
        user_role: 'Wybierz rolę',
        assignment_role: 'Rola',
        post: 'Stanowisko',
        sending: 'Wysyłanie',
        reset_password: 'Resetuj hasło',
        display_or_not: 'Wyświetlaj lub nie',
        document: 'Dokument',
        specificTemplates: 'Konkretne szablony',
        transferOrder: 'Zlecenie transferu',
        forwarding: 'Przekierowanie',
        one_click_notification: 'Powiadomienie o jednym kliknięciu',
        english_export: 'English Export',
        oneclick_download: 'Pobieranie jednym kliknięciem',
    },
    radio: {
        enabled: 'Włączone',
        disabled: 'Wyłączone',
        catalogue: 'Katalog',
        individual: 'Osobny',
        all: 'Wszystko',
        yes: 'Tak',
        no: 'Nie',
    },
    dropdown: {
        close_others: 'Zamknij inne',
        close_right: 'Zamknij z prawej',
        close_left: 'Zamknij z lewej',
    },
    table: {
        action: 'Działanie',
        explain: 'Wyjaśnienie',
        fileName: 'Nazwa załącznika',
        size: 'Rozmiar (MB)',
        uploader: 'Osoba przesyłająca',
        uploadTime: 'Czas przesłania',
        system: 'System',
        forwarding_time: 'Czas przesyłania',
        forwarder: 'Spedytor',
        send_to: 'Wyślij do',
        mode: 'Tryb',
    },
    pagination: {
        pages: ({ named }: Parameters<MessageFunction>[0]) => `${named('number')}/strona`,
        total: ({ named }: Parameters<MessageFunction>[0]) => `${named('number')} dane łącznie`,
        vinCount: ({ named }: Parameters<MessageFunction>[0]) => `${named('number')} pojazdy łącznie`,
    },
    confirm: {
        confirm_deletion: 'Potwierdzić usunięcie?',
        confirm_void: 'Potwierdzić unieważnienie?',
        confirm_release: 'Potwierdzić publikację?',
        ack: 'Potwierdzenie',
        cancel: 'Anuluj',
        non_records: 'Proszę wybrać co najmniej jeden rekord',
    },
    request: {
        failed_placeholder: 'Błąd żądania, błąd serwera',
        success_placeholder: 'Żądanie udane',
        auth: {
            login_success: 'Zalogowano pomyślnie',
        },
        error: {
            '404': 'Ścieżka żądania nie istnieje',
            '403': 'Brak uprawnień',
        },
    },
    page: {
        global: {
            page_search: 'Wyszukiwanie strony',
        },
        error: {
            not_found: 'Nie znaleziono strony',
            not_found_des: 'Nie ma tutaj niczego',
            auth_failed: 'Brak uprawnień',
            auth_failed_des: 'Dostęp do tej strony jest zabroniony',
            file_format_error: 'Niezwykły format pliku!',
            preview_failed: 'Podgląd nie powiódł się! Plik jest nietypowy!',
        },
        homepage: {
            homepage: homepage.homepage.plPL,
        },
        system: {
            menu: system.menu.plPL,
            dict: system.dict.plPL,
            dept: system.dept.plPL,
            role: system.role.plPL,
            user: system.user.plPL,
        },
        info: {
            announcement: Info.announcement.announcement.plPL,
            document: Info.document.document.plPL,
        },
        tech: {
            quality: {
                problemTracking: tech.quality.problemTracking.plPL,
                recallActivity: tech.quality.recallActivity.plPL,
                abnormalClaimTracking: tech.quality.abnormalClaimTracking.plPL,
            },
            management: {
                inquiryOd: tech.management.inquiry.plPL,
                permission: tech.management.permission.plPL,
                onSiteSupportHq: tech.management.onSiteSupport.plPL,
                progHistoryHq: tech.management.progHistory.plPL,
                salesRecordHq: tech.management.salesRecord.plPL,
                vehicleInfoHq: tech.management.vehicleInfo.plPL,
                claimHq: tech.management.claim.plPL,
                filterHq: tech.management.filter.plPL,
                newCarlssue: tech.management.newCarIssue.plPL,
                newCarDefinition: tech.management.newCarDefinition.plPL,
                vehicleProductionInfo: tech.management.vehicleProductionInfo.plPL,
                programmingHistoryInformationQuery: tech.management.programmingHistoryInformationQuery.plPL,
                ecuControllerInformationQuery: tech.management.ecuControllerInformationQuery.plPL,
            },
        },
        components: {
            businessComponents: tech.management.businessComponents.plPL,
        },
        statistics: statistics.inquiryStatistics.plPL,
        systemClassifyA: statistics.systemClassifyA.plPL,
        systemClassifyB: statistics.systemClassifyB.plPL,
        engineerStatistics: statistics.engineerStatistics.plPL,
        defectivePart: statistics.defectivePart.plPL,
        replyInquiryStatistics: statistics.replyInquiryStatistics.plPL,
        passRateStatistics: statistics.passRateStatistics.plPL,
        timelyResponseRateStatistics: statistics.timelyResponseRateStatistics.plPL,
        responseRateStatistics24Hour: statistics.responseRateStatistics24Hour.plPL,
        customsClearance135Rate: statistics.customsClearance135Rate.plPL,
        accumulatedClosureRate: statistics.accumulatedClosureRate.plPL,
        engineerAffiliatedOrganization: statistics.engineerAffiliatedOrganization.plPL,
        EngineerResponseRateReport: statistics.EngineerResponseRateReport.plPL,
    },
    validation: {
        common: {
            required: 'Ten element jest wymagany',
            numeric: 'Musi być liczbą całkowitą',
            decimal: 'To musi być numer',
            positive_interger: 'Musi być dodatnią liczbą całkowitą',
            up_to_digits: ({ named }: Parameters<MessageFunction>[0]) =>
                `Do${named('number')}cyfr`,
            more_than_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `Nie można przekroczyć${named('number')}słowo`,
            max_bytes: ({ named }: Parameters<MessageFunction>[0]) =>
                `Przekroczenie maksymalnej liczby znaków(${named('number')})`,
            less_than_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `Nie mniej niż${named('number')}słowo`,
            must_be_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `Musi być${named('number')}słowo`,
            greater_than: ({ named }: Parameters<MessageFunction>[0]) =>
                `Nie może być większa niż${named('number')}`,
            less_than: ({ named }: Parameters<MessageFunction>[0]) => `Nie może być mniejsza niż${named('number')}`,
            up_to_2_decimal_places: 'Do dwóch miejsc po przecinku',
            retain_up: 'Wprowadź do 10 dodatnich liczb całkowitych i zachowaj do 2 miejsc po przecinku',
            retain_ups: 'Wprowadź do 11 dodatnich liczb całkowitych i zachowaj nawet 4 miejsca po przecinku',
            input_number: 'Proszę wprowadzić numer',
            only_number_a: 'Można wprowadzać tylko litery lub cyfry',
            translate: 'Mogą być tylko litery',
            positive_integer: 'Wpisz co najmniej jedno pole',
            at_least_one: 'Wypełnij co najmniej jeden',
        },
        tip: {
            incomplete_information: 'Niekompletne informacje wypełnione',
        },
    },
    import: {
        template_file: 'Plik szablonu',
        browse: 'Przeglądaj',
        download_template: 'Pobierz szablon',
        no_file_selected: 'Nie wybrano pliku',
        incorrect_file_type: 'Nieprawidłowy typ pliku',
        exceed: 'Przekroczono limit',
        upload_success: 'Przesłano pomyślnie',
        upload_fail: 'Przesłanie nie powiodło się',
        again_upload: 'Można przesyłać tylko pliki obrazów w formatach jpg, png, bmp, gif, proszę przesłać ponownie',
        attachmentUpload: 'Przesłanie załącznika',
        supportedFormats: 'Obsługiwane formaty',
        preview: 'Podgląd',
    },
    export: {
        noDataToExport: 'Brak danych do eksportu',
    },
    select: {
        all: 'Całość',
    },
    checkbox: {
        select_all: 'Wybierz wszystko',
    },
    dialog: {
        error_message: 'Komunikat o błędzie',
    },
    ymd: {
        day: 'dzień',
        month: 'miesiąc',
        year: 'rok',
        january: 'Jan',
        february: 'Feb',
        march: 'Mar',
        april: 'Apr',
        may: 'May',
        june: 'Jun',
        july: 'Jul',
        august: 'Aug',
        september: 'Sep',
        october: 'Oct',
        november: 'Nov',
        december: 'Dec',
    },
};
