import type { MessageFunction } from 'vue-i18n';
import homepage from './pages/homepage/index';
import tech from './pages/tech/index';
import statistics from './pages/statistics/index';
import Info from './pages/info/index';
import system from './pages/system/index';
// 法
export default {
    license: {
        powered_by: 'Powered par Smil',
    },
    auth: {
        login: 'Connexion',
        username: 'Nom d\'utilisateur',
        username_placeholder: 'Entrez le nom d\'utilisateur',
        password: 'Mot de passe',
        password_placeholder: 'Entrez le mot de passe',
        code: 'Code de vérification',
        code_placeholder: 'Entrez le code de vérification',
        logout: 'Déconnexion',
        logout_success: 'Déconnexion réussie',
        day: 'Le jour',
        password_expiration: 'Le mot de passe a expiré, veuillez le modifier',
        expire: 'Garder le mot de passe jusqu\'à expiration',
        conseils:
            'Le mot de passe doit contenir des majuscules, des minuscules, des chiffres et des caractères spéciaux ({msg}) et doit contenir entre 8 et 16 caractères',
        brand_Selection: 'Sélection de marque',
        unread_notices: 'Notices non lus',
        new_cases: 'Nouveaux cas',
        unanswered_cases: 'Cas non répondu',
        overdue_cases: 'Cas en retard',
    },
    button: {
        query: 'Rechercher',
        editing_position: 'Poste éditorial',
        show_theme_editor: 'Afficher/Masquer l\'éditeur de thème',
        change_password: 'Modifier le mot de passe',
        old_password: 'Ancien mot de passe',
        new_password: 'Nouveau mot de passe',
        confirm_password: 'Confirmer le mot de passe',
        button: 'Bouton',
        about: 'À propos',
        back: 'Retour',
        go: 'Aller',
        home: 'Accueil',
        confirm: 'Confirmer',
        modify: 'Modifier',
        edit: 'éditer',
        new: 'Nouveau',
        cancel: 'Annuler',
        close: 'Fermé',
        search: 'Chercher',
        reset: 'Réinitialisation',
        delete: 'Supprimer',
        add: 'Ajouter',
        collapse: 'Réduire',
        expand: 'Plus',
        download_template: 'Télécharger le modèle',
        batch_import: 'Importation par lots',
        import: 'Importer',
        export: 'Exporter',
        export_fail: 'Échec de l\'exportation',
        detailed: 'Détails',
        details: 'Détail',
        download: 'Télécharger',
        toView: 'Voir',
        toViews: 'Voir les images',
        uploadAttachments: 'Télécharger',
        selectFile: 'Sélectionner un fichier',
        fillingInstructions: 'Instructions de remplissage',
        release: 'Communiqué',
        release_recall: 'Rappel de publication',
        save: 'Sauvegarder',
        send: 'Envoyer',
        tips: 'Conseils',
        send_back: 'Retour',
        examine: 'examiner',
        void: 'annuler',
        more: 'Plus',
        refresh: 'Actualiser',
        selects: 'Sélectionner',
        serial_number: 'Numéro de série',
        upload_progress: 'Progression du téléchargement',
        invalid: 'Invalide',
        daily_record: 'enregistrement quotidien',
        operation_type: 'Type d\'opération',
        field_name: 'Nom du champ',
        edit_role: 'Modifier le rôle',
        user_role: 'Rôle de l\'utilisateur',
        assignment_role: 'Définir le rôle',
        post: 'Postes',
        sending: 'En envoi',
        reset_password: 'Réinitialiser le mot de passe',
        display_or_not: 'Afficher ou non',
        document: 'Document',
        specificTemplates: 'Modèles spécifiques',
        transferOrder: 'Ordre de transfert',
        forwarding: 'Transfert',
        one_click_notification: 'Notification en un clic',
        english_export: 'English Export',
        oneclick_download: 'Téléchargement en un clic',
    },
    radio: {
        enabled: 'Activer',
        disabled: 'Désactiver',
        catalogue: 'Catalogue',
        individual: 'individus',
        all: 'Tous',
        yes: 'Oui',
        no: 'Non',
    },
    dropdown: {
        close_others: 'Fermer les autres',
        close_right: 'Fermer la droite',
        close_left: 'Fermer la gauche',
    },
    table: {
        action: 'Action',
        explain: 'Description',
        fileName: 'Nom du fichier',
        size: 'Taille (Mo)',
        uploader: 'Téléchargé par',
        uploadTime: 'Temps de Téléchargement ',
        forwarding_time: 'Temps de transmission',
        forwarder: 'Facilitateur',
        send_to: 'Envoyer à',
        mode: 'Mode',
    },
    pagination: {
        pages: ({ named }: Parameters<MessageFunction>[0]) => `${named('number')}/pages`,
        total: ({ named }: Parameters<MessageFunction>[0]) => `${named('number')} articles Données`,
        vinCount: ({ named }: Parameters<MessageFunction>[0]) =>
            `${named('number')} véhicules au total`,
    },
    confirm: {
        confirm_deletion: 'Confirmer la suppression?',
        confirm_void: 'Confirmer l\'annulation?',
        confirm_release: 'Confirmer la publication?',
        ack: 'OK',
        cancel: 'Annuler',
        non_records: 'Veuillez choisir au moins un enregistrement',
    },
    request: {
        failed_placeholder: 'Échec de la demande, erreur du serveur',
        success_placeholder: 'Demande réussie',
        auth: {
            login_success: 'Connexion réussie',
        },
        error: {
            '404': 'Url de demande introuvable',
            '403': 'Autorisation refusée',
        },
    },
    page: {
        global: {
            page_search: 'Recherche de page',
        },
        error: {
            not_found: 'Non trouvé',
            not_found_des: 'Il n\'y a rien ici',
            auth_failed: 'Authentification échouée',
            auth_failed_des: 'Cette page est inaccessible',
            file_format_error: 'Format de fichier anormal!',
            preview_failed: 'Aperçu échoué! Fichier anormal!',
        },
        homepage: {
            homepage: homepage.homepage.frFR,
        },
        system: {
            menu: system.menu.frFR,
            dict: system.dict.frFR,
            dept: system.dept.frFR,
            role: system.role.frFR,
            user: system.user.frFR,
        },
        info: {
            announcement: Info.announcement.announcement.frFR,
            document: Info.document.document.frFR,
        },
        tech: {
            quality: {
                problemTracking: tech.quality.problemTracking.frFR,
                recallActivity: tech.quality.recallActivity.frFR,
                abnormalClaimTracking: tech.quality.abnormalClaimTracking.frFR,
            },
            management: {
                inquiryOd: tech.management.inquiry.frFR,
                permission: tech.management.permission.frFR,
                onSiteSupportHq: tech.management.onSiteSupport.frFR,
                progHistoryHq: tech.management.progHistory.frFR,
                salesRecordHq: tech.management.salesRecord.frFR,
                vehicleInfoHq: tech.management.vehicleInfo.frFR,
                claimHq: tech.management.claim.frFR,
                filterHq: tech.management.filter.frFR,
                newCarlssue: tech.management.newCarIssue.frFR,
                newCarDefinition: tech.management.newCarDefinition.frFR,
                vehicleProductionInfo: tech.management.vehicleProductionInfo.frFR,
                programmingHistoryInformationQuery: tech.management.programmingHistoryInformationQuery.frFR,
                ecuControllerInformationQuery: tech.management.ecuControllerInformationQuery.frFR,
            },
        },
        components: {
            businessComponents: tech.management.businessComponents.frFR,
        },
        statistics: statistics.inquiryStatistics.frFR,
        systemClassifyA: statistics.systemClassifyA.frFR,
        systemClassifyB: statistics.systemClassifyB.frFR,
        engineerStatistics: statistics.engineerStatistics.frFR,
        defectivePart: statistics.defectivePart.frFR,
        replyInquiryStatistics: statistics.replyInquiryStatistics.frFR,
        passRateStatistics: statistics.passRateStatistics.frFR,
        timelyResponseRateStatistics: statistics.timelyResponseRateStatistics.frFR,
        responseRateStatistics24Hour: statistics.responseRateStatistics24Hour.frFR,
        customsClearance135Rate: statistics.customsClearance135Rate.frFR,
        accumulatedClosureRate: statistics.accumulatedClosureRate.frFR,
        engineerAffiliatedOrganization: statistics.engineerAffiliatedOrganization.frFR,
        EngineerResponseRateReport: statistics.EngineerResponseRateReport.frFR,
    },
    validation: {
        common: {
            required: 'Cet élément est obligatoire',
            numeric: 'Doit être un nombre entier',
            decimal: 'Doit être numérique',
            positive_interger: 'Doit être un entier positif',
            up_to_digits: ({ named }: Parameters<MessageFunction>[0]) =>
                `Au plus pour ${named('number')} Nombre de chiffres`,
            more_than_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `Ne peut dépasser ${named('number')} Mots`,
            max_bytes: ({ named }: Parameters<MessageFunction>[0]) =>
                `Dépasse le nombre maximal de caractères (${named('number')})`,
            less_than_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `Pas moins que ${named('number')} Mots`,
            must_be_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `Doit être ${named('number')} Mots`,
            greater_than: ({ named }: Parameters<MessageFunction>[0]) =>
                `Ne peut être supérieure à ${named('number')}`,
            less_than: ({ named }: Parameters<MessageFunction>[0]) =>
                `Ne peut être inférieure à ${named('number')}`,
            up_to_2_decimal_places: 'Jusqu\'à 2 décimales',
            retain_up:
                'Entrez jusqu\'à 10 chiffres entiers positifs et conservez jusqu\'à 2 chiffres décimaux',
            retain_ups:
                'Entrez jusqu\'à 11 chiffres entiers positifs et conservez jusqu\'à 4 chiffres décimaux',
            input_number: 'Veuillez entrer un numéro',
            only_number_a: 'Entrez uniquement des lettres ou des chiffres',
            translate: 'Uniquement pour les lettres',
            positive_integer: 'Entrez au moins un champ',
            at_least_one: 'Remplissez au moins un',
        },
        tip: {
            incomplete_information: 'Information Incomplète',
        },
    },
    import: {
        template_file: 'Fichier modèle',
        browse: 'Feuilleter',
        download_template: 'Télécharger le modèle',
        no_file_selected: 'Aucun fichier sélectionné',
        incorrect_file_type: 'Type de fichier incorrect',
        exceed: 'Au - delà',
        upload_success: 'Téléchargement réussi',
        upload_fail: 'Le téléchargement a échoué',
        again_upload:
            'Seuls les fichiers Image au format jpg、png、bmp、gif peuvent être téléchargés, veuillez les télécharger à nouveau',
        attachmentUpload: 'Téléchargement de pièces jointes',
        supportedFormats: 'Formats pris en charge',
        preview: 'Aperçu',
    },
    export: {
        noDataToExport: 'Pas de données exportables',
    },
    select: {
        all: 'Tous',
    },
    checkbox: {
        select_all: 'Tout sélectionner',
    },
    dialog: {
        error_message: 'Informations erronées',
    },
    ymd: {
        day: 'jour',
        month: 'mois',
        year: 'an',
        january: 'Jan',
        february: 'Feb',
        march: 'Mar',
        april: 'Apr',
        may: 'May',
        june: 'Jun',
        july: 'Jul',
        august: 'Aug',
        september: 'Sep',
        october: 'Oct',
        november: 'Nov',
        december: 'Dec',
    },
};
